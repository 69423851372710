import { CoreStats } from "../../model/CoreStats";
import { ThrowSummary } from "../../model/throwSummary";
import { advanceRatio } from "../../summaryUtils";
import { feetToMeters, mphToKph, mul } from "../../utils/math";
import { ClientCoreMetrics, CoreMetrics } from "./analysisSet";
import { ThrowMetrics } from "./stockShot";

export const convertStatsToMetrics = (coreStats: CoreStats) => {
  const spinRpm = mul(Math.abs(coreStats?.rotPerSec ?? 0), 60).toNumber();

  return {
    spinRpm,
    speedMph: coreStats?.speedMph,
    speedKmh: mphToKph(coreStats?.speedMph ?? 0),
    hyzerAngle: coreStats?.correctedHyzerAngle ?? coreStats?.hyzerAngle,
    noseAngle: coreStats?.correctedNoseAngle ?? coreStats?.noseAngle,
    advanceRatio: mul(advanceRatio(coreStats), 100).toNumber(),
    launchAngle: coreStats?.uphillAngle,
    wobble: coreStats?.offAxisDegrees,
    distanceFeet: coreStats?.estimatedFeet ?? 0,
    distanceMeters: feetToMeters(coreStats?.estimatedFeet ?? 0),
  };
};

export const removeClientMetrics = (coreMetrics: CoreMetrics) => {
  const {
    advanceRatio,
    distanceFeet,
    distanceMeters,
    launchAngle,
    spinRpm,
    speedKmh,
    wobble,
    hyzerAngle,
    noseAngle,
    ...coreStats
  } = coreMetrics;
};

const coreMetricsConverter = (
  id: string,
  coreStats?: ThrowMetrics & { estimatedFeet?: number },
): ClientCoreMetrics | null => {
  if (!coreStats) return null;
  return {
    speedMph: coreStats?.speedMph,
    speedKmh: mphToKph(coreStats?.speedMph ?? 0),
    spinRpm: Math.abs(mul(coreStats?.rotPerSec ?? 0, 60).toNumber()),
    hyzerAngle: coreStats?.correctedHyzerAngle ?? coreStats?.hyzerAngle,
    noseAngle: coreStats?.correctedNoseAngle ?? coreStats?.noseAngle,
    advanceRatio: mul(advanceRatio(coreStats), 100).toNumber(),
    launchAngle: coreStats?.uphillAngle,
    wobble: coreStats?.offAxisDegrees,
    distanceFeet: coreStats?.distanceFeet ?? coreStats?.estimatedFeet ?? 0,
    distanceMeters: coreStats?.distanceMeters ?? feetToMeters(coreStats?.estimatedFeet ?? 0),
    originalSummary: { id, ...coreStats } as ThrowSummary,
  };
};

export const removeCoreMetrics = (coreMetrics: ClientCoreMetrics) => {
  const {
    advanceRatio,
    distanceFeet,
    distanceMeters,
    launchAngle,
    spinRpm,
    speedKmh,
    wobble,
    hyzerAngle,
    noseAngle,
    ...coreStats
  } = coreMetrics;

  return { ...coreStats };
};

export default coreMetricsConverter;
