import { ThrowSummaryAndId } from "../latestDashboard";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { downloadTrackEffect, TrackPoint } from "../trackUtils";
import { Group } from "three";
import { animateThrow, loadDiscModel } from "../3dUtils";
import { getUidQueryParam } from "../queryUtils";
import { Link as RouterLink, useParams } from "react-router-dom";
import { useGlobal } from "../components/providers/GlobalProvider";
import { Box, Link } from "@mui/material";

export function loadModelEffect(setDiscScene: (a: Group) => void) {
  return () => {
    let isCancelled = false;
    (async () => {
      const group = await loadDiscModel();
      if (!isCancelled) {
        setDiscScene(group);
      }
    })();
    return () => {
      isCancelled = true;
    };
  };
}

export default function Flight3D(props: { flights: ThrowSummaryAndId[]; sx?: any }) {
  const summary = props.flights[0];
  const [summaryId, setSummaryId] = useState(summary.id);
  const [track, setTrack] = useState<TrackPoint[]>();
  const [discScene, setDiscScene] = useState<Group>();
  const containerEl = useRef<HTMLCanvasElement>(null);
  const canvasEl = useRef<HTMLCanvasElement>(null);
  const { userId } = useGlobal();
  const { userId: userIdParam } = useParams();

  useEffect(() => {
    if (summary.id != summaryId) {
      setSummaryId(summary.id);
      setTrack(undefined);
    }
  }, [summary, summary.id, summaryId]);

  useEffect(() => {
    downloadTrackEffect(summary.id, setTrack, summary.estimatedFeet, userIdParam || userId);
  }, [summary.id, summary.estimatedFeet, userId, userIdParam]);

  useEffect(loadModelEffect(setDiscScene), []);

  useEffect(() => {
    if (track?.length && canvasEl.current && discScene) {
      return animateThrow(canvasEl.current, summary, [track], discScene, { addGui: false });
    }
  }, [track, discScene, summary]);

  useEffect(() => {
    window.addEventListener("resize", () => {
      if (containerEl.current && canvasEl.current) {
        canvasEl.current.style.width = containerEl.current.clientWidth + "px";
        canvasEl.current.style.height =
          Math.round((containerEl.current.clientWidth * 9.0) / 16) + "px";
      }
    });
    if (containerEl.current && canvasEl.current) {
      canvasEl.current.width = containerEl.current.clientWidth;
      canvasEl.current.height = Math.round((containerEl.current.clientWidth * 9.0) / 16);
    }
  }, [containerEl, canvasEl]);

  return (
    <Box
      ref={containerEl}
      component="div"
      sx={{
        position: "relative",
        width: "100%",
        height: "auto",
        aspectRatio: "16/9",
      }}
    >
      <Link
        component={RouterLink}
        target="_blank"
        to={"/simulate?" + getUidQueryParam() + "throwId=" + summaryId}
        rel="noreferrer"
      >
        <Box
          component="canvas"
          ref={canvasEl}
          sx={{
            width: "100%",
            height: "100%",
            aspectRatio: "16/9",
          }}
        ></Box>
      </Link>
    </Box>
  );
}
