import * as React from "react";
import { useEffect } from "react";
import JSONView from "react-json-view";
import { getAuth } from "firebase/auth";
import { Timestamp } from "firebase/firestore";
import { useAuthState } from "react-firebase-hooks/auth";
import { firebaseApp } from "../firebaseConfig";
import { Box, Container, Grid, CssBaseline } from "@mui/material";
import { BuildDevice } from "../model/device";
import { getRootDeviceDoc, getTrueUserId, POWER_USER_ID, storeBuildDevice } from "../summaryUtils";
import { useDocument } from "react-firebase-hooks/firestore";
import {
  AccelCalibrateKey,
  CalibrationValues,
  DeviceReading,
  DeviceReadings,
} from "../model/calibrate";
import { Spinner } from "@blueprintjs/core";
import { DeviceComputedFields, DeviceEditFields, DeviceStaticFields } from "./DeviceEditFields";
import { CalibrationPage, LastCalibration } from "./CalibrationPage";
import { renderDebugOrientation } from "../3dmini";
import useIds from "../hooks/useIds";
import { useParams } from "react-router-dom";

export interface BuildProps {
  deviceUid: string;
  mac?: string;
  softwareRev?: string;
  connected: boolean;
  calibrating: boolean;
  lastReading?: DeviceReading;
  readings?: DeviceReadings;
  calibrateDirection: (direction: AccelCalibrateKey) => Promise<void>;
  calibrate: () => Promise<CalibrationValues | undefined>;
  resendToDisc: (values: CalibrationValues) => Promise<void>;
  renderDebugOrientation: (canvas: Element) => void;
  cancelCalibration: () => void;
}

function storeUsbCharging(props: BuildProps, rawData: BuildDevice | undefined) {
  if (!props.lastReading) {
    return;
  }

  if (props.lastReading.usbCc1 && props.lastReading.usbCc2) {
    // both usb are on, which is not a healthy state
    return;
  }

  if (props.lastReading.usbCc1 && props.lastReading.isCharging && !rawData?.cc1ChargingVolts) {
    let toStore: Partial<BuildDevice> = {
      cc1ChargingTime: Date.now(),
    };

    if (props.lastReading.battVolts) {
      toStore = { ...toStore, cc1ChargingVolts: props.lastReading.battVolts };
    }

    storeBuildDevice(props.deviceUid, toStore);
  }

  if (props.lastReading.usbCc2 && props.lastReading.isCharging && !rawData?.cc2ChargingVolts) {
    let toStore: Partial<BuildDevice> = {
      cc2ChargingTime: Date.now(),
    };

    if (props.lastReading.battVolts) {
      toStore = { ...toStore, cc2ChargingVolts: props.lastReading.battVolts };
    }

    storeBuildDevice(props.deviceUid, toStore);
  }
}

export function BuildWrapper(props: BuildProps) {
  const { deviceUid } = useParams();
  if (!props.deviceUid && !deviceUid) {
    return;
  }
  const deviceId = props.deviceUid || deviceUid || "";

  return <BuildDashboard {...props} deviceUid={deviceId} />;
}

export function BuildDashboard(props: BuildProps) {
  const [user, userLoading, userError] = useAuthState(getAuth(firebaseApp));
  const [deviceDoc, isLoading, error] = useDocument(getRootDeviceDoc(props.deviceUid));
  const rawData = deviceDoc?.data() as BuildDevice | undefined;

  useEffect(() => {
    if (
      props.connected &&
      !rawData?.mac &&
      !rawData?.firstConnectedUser &&
      props.mac &&
      props.deviceUid &&
      user &&
      !isLoading &&
      !error
    ) {
      // if no device exists, populate it
      const toStore: BuildDevice = {
        uid: props.deviceUid,
        mac: props.mac,
        initialSoftwareRev: props.softwareRev,
        firstConnectedTime: Timestamp.now(),
        firstConnectedUser: getTrueUserId(),
      };

      if (props.lastReading?.battVolts) {
        toStore.adcBatteryVoltage = props.lastReading.battVolts;
      }

      if (props.lastReading?.sampleNanos) {
        toStore.sensorHz = 1.0e9 / props.lastReading.sampleNanos;
      }

      storeBuildDevice(props.deviceUid, toStore);
    }
  }, [props.connected, deviceDoc, props.mac, props.deviceUid, user, isLoading, error]);

  useEffect(() => {
    if (!rawData || !rawData.uid) {
      return;
    }
    if (rawData.sensorHz && rawData.adcBatteryVoltage) {
      return;
    }
    if (!props.lastReading?.battVolts || !props.lastReading?.sampleNanos) {
      return;
    }
    const toStore: Partial<BuildDevice> = {};

    if (props.lastReading?.battVolts) {
      toStore.adcBatteryVoltage = props.lastReading.battVolts;
    }

    if (props.lastReading?.sampleNanos) {
      toStore.sensorHz = 1.0e9 / props.lastReading.sampleNanos;
    }

    storeBuildDevice(props.deviceUid!, toStore);
  }, [rawData, props.lastReading?.battVolts]);

  useEffect(
    () => storeUsbCharging(props, rawData),
    [
      props.deviceUid,
      props.lastReading?.usbCc1,
      props.lastReading?.usbCc2,
      props.lastReading?.isCharging,
    ],
  );

  useEffect(() => {
    if (!props.lastReading || rawData?.wcoSwitchoverTime) {
      return;
    }
    const toStore: BuildDevice = {
      uid: props.deviceUid,
      wcoSwitchoverTime: Date.now(),
    };

    storeBuildDevice(props.deviceUid, toStore);
  }, [props.deviceUid, props.lastReading?.wcoEnabled]);

  if (isLoading || userLoading || error || !rawData?.uid || !rawData?.mac) {
    return <Spinner />;
  }

  const device: BuildDevice = rawData;

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <Box
        component="main"
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === "light" ? theme.palette.grey[100] : theme.palette.grey[900],
          flexGrow: 1,
          overflow: "auto",
        }}
      >
        <Container sx={{ mt: 4, mb: 4 }}>
          <Grid container spacing={3}>
            <Grid item mobile={6}>
              <DeviceStaticFields device={device} lastReading={props.lastReading} />
            </Grid>
            {/*<Grid item mobile={4} sx={{ marginLeft: "auto" }}>*/}
            {/*  <canvas*/}
            {/*    id={"mini3d-build-dash"}*/}
            {/*    width={300}*/}
            {/*    height={300}*/}
            {/*    hidden={!props.connected || !props.calibrating}*/}
            {/*  />*/}
            {/*</Grid>*/}
            <Grid item mobile={6}>
              <DeviceComputedFields device={device} />
            </Grid>
            <Grid item mobile={12}>
              <CalibrationPage
                {...props}
                key={device.uid}
                calibrate={async () => {
                  const result = await props.calibrate();
                  const canvas = document.querySelector("#mini3d-build-dash");
                  if (canvas) {
                    // TODO: put this back if it's useful
                    // This is disabled because it causes a lot of traffic times 6 when calibrating
                    // props.renderDebugOrientation(canvas);
                  }
                  return result;
                }}
                device={device}
              />
            </Grid>
            <LastCalibration {...props} device={device} />
            <Grid item mobile={6}>
              <DeviceEditFields device={device} />
            </Grid>
            {getTrueUserId() == POWER_USER_ID && (
              <Grid item mobile={12}>
                <JSONView src={device} />
              </Grid>
            )}
          </Grid>
        </Container>
      </Box>
    </Box>
  );
}
