import { EmailAuthProvider, GoogleAuthProvider, OAuthProvider } from "firebase/auth";
import { auth } from "firebaseui";

export function authUiConfig(): auth.Config {
  const appleOauth = new OAuthProvider("apple.com");
  appleOauth.addScope("email");
  appleOauth.addScope("name");
  return {
    // signInFlow: isSignInWithEmailLink(getAuth(firebaseApp), window.location.href)
    //   ? "redirect"
    //   : "popup",
    signInFlow: "popup",
    // signInSuccessUrl: '',
    // signInSuccess: () => {},
    tosUrl: "/terms",
    privacyPolicyUrl: "/privacy-policy",
    signInOptions: [
      EmailAuthProvider.PROVIDER_ID,
      {
        provider: GoogleAuthProvider.PROVIDER_ID,
        customParameters: {
          prompt: "select_account",
        },
      },
      "apple.com",

      // {
      //   provider: EmailAuthProvider.PROVIDER_ID,
      //   signInMethod: EmailAuthProvider.EMAIL_LINK_SIGN_IN_METHOD,
      // },
    ],
  };
}
