import { getAnalytics, logEvent } from "firebase/analytics";
import { getAuth } from "firebase/auth";

export function trackEvent(eventName: string, eventData: Record<string, any> = {}) {
  logEvent(getAnalytics(), eventName, {
    ...eventData,
    userId: getAuth().currentUser?.uid,
    client: "web",
  });
}
