import Big from "big.js";
import { CoreStats } from "../model/CoreStats";
import { Timestamp } from "firebase/firestore";

// Helper function to ensure input is a Big instance

// Existing code...

// Conversion factors
const kmToMilesFactor = new Big(0.621371);
const feetToMetersFactor = new Big(0.3048);

// Helper function to ensure input is a Big instance
export const toBig = (input: Big | string | number): Big => {
  if (!input) {
    return new Big(0);
  }
  return input instanceof Big ? input : new Big(input);
};

/**
 * Converts meters to feet.
 * @param meters The length in meters.
 * @returns The length in feet.
 */
export const metersToFeet = (meters: Big | number): number => {
  // Conversion factor for meters to feet is the inverse of feet to meters factor
  return toBig(meters).div(feetToMetersFactor).toNumber();
};

/**
 * Converts kilometers per hour to miles per hour.
 * @param kmh The speed in kilometers per hour.
 * @returns The speed in miles per hour.
 */
export const kmhToMph = (kmh: Big | number): number => {
  // Utilizing the conversion factor directly for km to miles
  return toBig(kmh).times(kmToMilesFactor).toNumber();
};

/**
 * Converts feet to meters.
 * @param feet The length in feet.
 * @returns The length in meters.
 */
export const feetToMeters = (feet: Big | number): number => {
  return toBig(feet).times(feetToMetersFactor).toNumber();
};

/**
 * Converts miles per hour to kilometers per hour.
 * @param mph The speed in miles per hour.
 * @returns The speed in kilometers per hour.
 */
export const mphToKph = (mph: Big | number): number => {
  // Utilizing the same inversion logic as in milesToKm for consistency in conversion.
  return toBig(mph).div(kmToMilesFactor).toNumber();
};

export const averageThrowMetrics = (throws: CoreStats[]) => {
  const metrics = throws.reduce(
    (avg, summary, i, throws) => {
      const isLastThrow = throws.length - 1 === i;
      const newAvg = {
        speedMph: isLastThrow
          ? div(add(avg.speedMph, summary.speedMph), throws.length).toNumber()
          : add(avg.speedMph, summary.speedMph).toNumber(),
        rotPerSec: isLastThrow
          ? div(add(avg.rotPerSec, summary.rotPerSec), throws.length).toNumber()
          : add(avg.rotPerSec, summary.rotPerSec).toNumber(),
        hyzerAngle: isLastThrow
          ? div(add(avg.hyzerAngle, summary.hyzerAngle), throws.length).toNumber()
          : add(avg.hyzerAngle, summary.hyzerAngle).toNumber(),
        noseAngle: isLastThrow
          ? div(add(avg.noseAngle, summary.noseAngle), throws.length).toNumber()
          : add(avg.noseAngle, summary.noseAngle).toNumber(),
        uphillAngle: isLastThrow
          ? div(add(avg.uphillAngle, summary.uphillAngle), throws.length).toNumber()
          : add(avg.uphillAngle, summary.uphillAngle).toNumber(),
        offAxisDegrees: isLastThrow
          ? div(add(avg.offAxisDegrees, summary.offAxisDegrees), throws.length).toNumber()
          : add(avg.offAxisDegrees, summary.offAxisDegrees).toNumber(),
      };

      return newAvg;
    },
    {
      speedMph: 0,
      rotPerSec: 0,
      hyzerAngle: 0,
      noseAngle: 0,
      offAxisDegrees: 0,
      uphillAngle: 0,
    },
  );

  return {
    ...metrics,
    throwTime: Timestamp.fromMillis(
      throws.map((v) => v.throwTime.toDate().valueOf()).reduce((a, b) => a + b, 0) / throws.length,
    ),
    upsideDown:
      throws.filter((v) => v.upsideDown).length > throws.filter((v) => !v.upsideDown).length,
  };
};

export const lte = (a: Big | number, b: Big | number) => toBig(a).lte(toBig(b));
export const gte = (a: Big | number, b: Big | number) => toBig(a).gte(toBig(b));
export const add = (a: Big | number, b: Big | number) => toBig(a).plus(toBig(b));
export const sub = (a: Big | number, b: Big | number) => toBig(a).minus(toBig(b));
export const mul = (a: Big | number, b: Big | number) => toBig(a).times(toBig(b));
export const div = (a: Big | number, b: Big | number) => toBig(a).div(toBig(b));
export const mod = (a: Big | number, b: Big | number) => toBig(a).mod(toBig(b));
export const sqrt = (a: Big | number) => toBig(a).sqrt();
export const squared = (a: Big | number): Big => toBig(a).times(toBig(a));
export const lt = (a: Big | number, b: Big | number) => toBig(a).lt(toBig(b));
export const gt = (a: Big | number, b: Big | number) => toBig(a).gt(toBig(b));

export const floatSort = (a: Big | number, b: Big | number) => {
  if (lte(a, b)) {
    return -1;
  } else if (gte(a, b)) {
    return 1;
  }
  return 0;
};
