import {
  FirestoreDataConverter,
  QueryDocumentSnapshot,
  SnapshotOptions,
  type Timestamp,
} from "firebase/firestore";

export type LiveEventDocument = {
  name: string;
  startDate: Timestamp;
  endDate: Timestamp;
  previewDate: Timestamp;
};

export type LiveEvent = LiveEventDocument & {
  id: string;
};

export const liveEventConverter: FirestoreDataConverter<LiveEvent> = {
  fromFirestore: (
    snapshot: QueryDocumentSnapshot<LiveEventDocument>,
    options: SnapshotOptions,
  ): LiveEvent => {
    const data = snapshot.data(options);

    return {
      ...data,
      id: snapshot.id,
    } as LiveEvent; // Adjust this mapping as necessary
  },
  toFirestore: (modelObject: LiveEvent): LiveEventDocument => {
    const { id, ...rest } = modelObject;
    return rest;
  },
};
