import {
  DocumentData,
  FirestoreDataConverter,
  QueryDocumentSnapshot,
  SnapshotOptions,
} from "firebase/firestore";
import { ThrowSummary } from "../../model/throwSummary";
import coreMetricsConverter, { removeCoreMetrics } from "./coreMetrics";

export const throwSummaryConverter: FirestoreDataConverter<ThrowSummary> = {
  fromFirestore: (snapshot: QueryDocumentSnapshot<ThrowSummary>, options: SnapshotOptions) => {
    const data = snapshot.data(options);
    const loading = !data.speedMph;

    return {
      ...data,
      id: snapshot.id,
      loading,
      ...(!loading ? coreMetricsConverter(snapshot.id, data) : {}),
    } as ThrowSummary;
  },
  toFirestore: (modelObject: ThrowSummary): DocumentData => {
    const copy = removeCoreMetrics(modelObject);
    // @ts-ignore
    delete copy.id;
    // @ts-ignore
    delete copy.loading;
    return copy;
  },
};
