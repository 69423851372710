import { PartialWithFieldValue, SetOptions, Transaction } from "@firebase/firestore";
import { getAnalytics, logEvent } from "firebase/analytics";
import { getAuth, type User } from "firebase/auth";
import {
  addDoc,
  arrayUnion,
  collection,
  type CollectionReference,
  deleteDoc,
  doc,
  DocumentData,
  type DocumentReference,
  getDoc,
  getDocs,
  getFirestore,
  limit,
  query,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { LIVE_USER_ID } from "./dashboard/liveEvents";
import { getAnalysisSetCollection } from "./firebase/collections";
import { ThrowAnalysis, throwAnalysisConverter } from "./firebase/converters/analysisSet";
import {
  leaderboardStoreMetadataConverter,
  StoreMetadata,
} from "./firebase/converters/leaderboardStoreMetadata";
import { leaderboardUserMetadataConverter } from "./firebase/converters/leaderboardUserMetadata";
import { ThrowMetrics } from "./firebase/converters/stockShot";
import { throwSummaryConverter } from "./firebase/converters/throwSummary";
import { getAnalysisSet } from "./firebase/docs";
import { firebaseApp } from "./firebaseConfig";
import { CoreStats } from "./model/CoreStats";
import { BuildDevice, Device, DeviceCalibration } from "./model/device";
import { LeaderboardStoreMetadata, LeaderboardUserMetadata } from "./model/leaderboard";
import { AnalysisSet, ThrowSummary } from "./model/throwSummary";
import UserSettings from "./model/UserSettings";
import { getQueryMap } from "./queryUtils";
import { div, mul } from "./utils/math";

export const UNKNOWN_USER_ID = "unknown";

export function getHyzer(summary: CoreStats): number {
  return summary.correctedHyzerAngle || summary.hyzerAngle;
}

export function getNose(summary: CoreStats): number {
  return summary.correctedNoseAngle || summary.noseAngle;
}

export function getTrueUserId(): string {
  return getUserId(null, true);
}

export function getUserId(
  user: Pick<User, "uid"> | undefined | null = null,
  trueUser = false,
): string {
  const queryMap = getQueryMap();
  if (queryMap.has("uid") && queryMap.get("uid") && !trueUser) {
    return queryMap.get("uid") as string;
  }

  if (!trueUser && window.location.pathname.startsWith("/live")) {
    return LIVE_USER_ID;
  }

  if (!user) {
    user = getAuth(firebaseApp).currentUser;
    if (!user) {
      return UNKNOWN_USER_ID;
    }
  }

  return user.uid;
}

export async function createAnalysisSet(
  uid: string,
  toStore: AnalysisSet,
): Promise<DocumentReference> {
  return addDoc(getAnalysisSetCollection(uid), toStore);
}

export async function setDocWithLogging<T>(
  reference: DocumentReference<T> | null,
  data: PartialWithFieldValue<T>,
  options: SetOptions,
  errorMetadata?: { [key: string]: any },
  transaction?: Transaction,
): Promise<void> {
  if (!reference) {
    return;
  }
  try {
    // @ts-ignore
    Object.keys(data).forEach((key) => {
      // @ts-ignore
      if (data[key] === undefined) {
        console.error("undefined value for key, this will cause firebase to fail: " + key);
        // @ts-ignore
        delete data[key];
      }
    });

    await (transaction
      ? transaction.set(reference, data, options)
      : setDoc(reference, data, options));
  } catch (error) {
    logEvent(getAnalytics(firebaseApp), "firebase_api_error", {
      error,
      client: "web",
      type: "firestore_write_failed",
      path: reference.path,
      ...(errorMetadata ?? {}),
    });
    throw error;
  }
}

export async function storeLeaderboardUserMetadata(
  uid: string,
  toStore: PartialWithFieldValue<LeaderboardUserMetadata>,
): Promise<void> {
  logEvent(getAnalytics(firebaseApp), "store_leaderboard_user_metadata");
  const collectionPath = "/leaderboard/users/userMetadata";
  const store = getFirestore(firebaseApp);
  const metadataDoc = doc(store, collectionPath, uid).withConverter(
    leaderboardUserMetadataConverter,
  );
  return setDocWithLogging(metadataDoc, toStore as DocumentData, { merge: true });
}

export async function deleteDocWithLogging<T>(
  reference: DocumentReference<T>,
  errorMetadata?: { [key: string]: any },
): Promise<void> {
  try {
    await deleteDoc(reference);
  } catch (error) {
    logEvent(getAnalytics(firebaseApp), "firebase_api_error", {
      error,
      client: "web",
      type: "firestore_delete_failed",
      path: reference.path,
      ...(errorMetadata ?? {}),
    });
    throw error;
  }
}

export async function storeLeaderboardMetadata(
  uid: string,
  toStore: PartialWithFieldValue<LeaderboardUserMetadata>,
): Promise<void> {
  logEvent(getAnalytics(firebaseApp), "store_leaderboard_user_metadata");
  const collectionPath = "/leaderboard/users/userMetadata";
  const store = getFirestore(firebaseApp);
  const metadataDoc = doc(store, collectionPath, uid);
  return setDocWithLogging(metadataDoc, toStore as DocumentData, { merge: true });
}

export async function saveLeaderboardStoreMetadata<TConverter extends boolean = true>(
  uid: string,
  toStore: PartialWithFieldValue<
    TConverter extends false ? LeaderboardStoreMetadata : StoreMetadata
  >,
  converter?: TConverter,
): Promise<void> {
  logEvent(getAnalytics(firebaseApp), "store_leaderboard_store_metadata");
  const collectionPath = "/leaderboard/users/storeMetadata";
  const store = getFirestore(firebaseApp);

  const metadataDoc =
    converter != null && !converter
      ? doc(store, collectionPath, uid)
      : doc(store, collectionPath, uid).withConverter(leaderboardStoreMetadataConverter);
  return setDocWithLogging(metadataDoc, toStore as DocumentData, { merge: true });
}

export async function storeCustomerCheckoutSession(
  uid: string,
  id: string,
  toStore: object,
): Promise<void> {
  logEvent(getAnalytics(firebaseApp), "create_stripe_checkout_session");
  const store = getFirestore(firebaseApp);
  const path = "/stripe/data/customers/" + uid + "/checkout_sessions";
  const checkouts: CollectionReference = collection(store, path);

  return setDocWithLogging(doc(checkouts, id), toStore as DocumentData, { merge: true });
}

export async function deleteAnalysisSet(uid: string, id: string): Promise<void> {
  logEvent(getAnalytics(firebaseApp), "delete_analysis"); // is delete_analysis ok here?
  return deleteDocWithLogging(getAnalysisDoc(uid, id));
}

export async function storeAnalysisSet(
  uid: string,
  id: string,
  toStore: PartialWithFieldValue<AnalysisSet>,
): Promise<void> {
  logEvent(getAnalytics(firebaseApp), "create_analysis");
  return setDocWithLogging(getAnalysisDoc(uid, id), toStore as DocumentData, { merge: true });
}

export async function storeSummary(
  uid: string,
  id: string,
  toStore: Partial<ThrowSummary>,
  errorMetadata?: { [key: string]: any },
  transaction?: Transaction,
): Promise<void> {
  return setDocWithLogging(
    getSummaryDoc(uid, id),
    toStore as DocumentData,
    { merge: true },
    toStore,
    transaction,
  );
}

export async function updateSummaryTags(uid: string, id: string, tags: string[]): Promise<void> {
  return updateDoc(getSummaryDoc(uid, id), { tags: arrayUnion(...tags) });
}

export async function shareAnalysisSet(userId: string, id: string): Promise<void> {
  const ref = getAnalysisSet(userId, id);

  if (ref) {
    const analysisSet = (await getDoc(ref)).data();
    if (analysisSet?.ids) {
      await Promise.all(
        analysisSet.ids.map((id: string) => {
          return storeSummary(getUserId(), id, { visibility: "public" });
        }),
      );
    }

    await updateDoc(ref, { visibility: "public" });
  }
}

export async function storeUserSettings(
  uid: string,
  toStore: Partial<UserSettings>,
): Promise<void> {
  return setDocWithLogging(getUserDoc(uid), toStore as DocumentData, { merge: true });
}

export function getLeaderboardUserMetadata(
  uid?: string,
): DocumentReference<LeaderboardUserMetadata> | null {
  if (!uid) {
    return null;
  }
  const collectionPath = "/leaderboard/users/userMetadata";
  const store = getFirestore(firebaseApp);
  return doc(collection(store, collectionPath), uid);
}
export function getLeaderboardStoreMetadata(
  uid?: string,
): DocumentReference<LeaderboardStoreMetadata> | null {
  if (!uid) {
    return null;
  }
  const collectionPath = "/leaderboard/users/storeMetadata";
  const store = getFirestore(firebaseApp);
  return doc(collection(store, collectionPath), uid);
}

export function getUserDoc(uid: string): DocumentReference<UserSettings> {
  const store = getFirestore(firebaseApp);
  return doc(collection(store, "/users"), uid);
}

export function getSummaryDoc(uid: string, id: string): DocumentReference<ThrowSummary> {
  const summaryPath = "/users/" + uid + "/throw-summary/";
  const store = getFirestore(firebaseApp);
  return doc(collection(store, summaryPath), id).withConverter(throwSummaryConverter);
}

export function getLeaderboardWeeklyCollection(): CollectionReference {
  return collection(getFirestore(firebaseApp), "/leaderboard/dates/weekly/");
}

export function getAnalysisDoc(uid: string, id: string): DocumentReference<ThrowAnalysis> {
  return doc(getAnalysisSetCollection(uid), id).withConverter(throwAnalysisConverter);
}

function getDevicesCollection(uid: string): CollectionReference {
  const summaryPath = "/users/" + uid + "/devices/";
  const store = getFirestore(firebaseApp);
  return collection(store, summaryPath);
}

export function getRootDeviceDoc(deviceId: string): DocumentReference {
  const summaryPath = "/devices/";
  const store = getFirestore(firebaseApp);
  return doc(collection(store, summaryPath), deviceId);
}

export function getDeviceDoc(uid: string, id: string): DocumentReference {
  const summaryPath = "/users/" + uid + "/devices/";
  const store = getFirestore(firebaseApp);
  return doc(collection(store, summaryPath), id);
}

function getCalibrationDoc(
  uid: string,
  deviceId: string,
  time: string,
  isRoot: boolean = false,
): DocumentReference {
  const summaryPath = isRoot
    ? "/devices/" + deviceId + "/calibration/"
    : "/users/" + uid + "/devices/" + deviceId + "/calibration/";
  const store = getFirestore(firebaseApp);
  return doc(collection(store, summaryPath), time);
}

export async function loadSummary(
  uid: string,
  id: string,
  transaction?: Transaction,
): Promise<ThrowSummary | undefined> {
  const document = getSummaryDoc(uid, id).withConverter(throwSummaryConverter);
  // Let summaryPath = "/users/" + uid + "/throw-summary/";
  // let result = await firebase.firestore().collection(summaryPath).doc(id).get();
  const result = await (transaction ? transaction.get(document) : getDoc(document));
  // @ts-ignore
  const ret: ThrowSummary = result.data();
  return ret;
}

export async function loadAnalysisSet(uid: string, id: string): Promise<AnalysisSet | undefined> {
  const document = getAnalysisDoc(uid, id);
  const result = await getDoc(document);
  // @ts-ignore
  const ret: AnalysisSet | undefined = result.data();
  return ret;
}

export async function storeCalibrationData(
  uid: string,
  deviceId: string,
  time: string,
  toStore: DeviceCalibration,
  isRoot: boolean = false,
): Promise<void> {
  const document = getCalibrationDoc(uid, deviceId, time, isRoot);
  return setDocWithLogging(document, toStore, { merge: true });
}

export async function storeBuildDevice(
  deviceUid: string,
  toStore: Partial<BuildDevice>,
): Promise<void> {
  const document = getRootDeviceDoc(deviceUid);
  return setDocWithLogging(document, toStore, { merge: true });
}

export async function loadBuildDevice(deviceId: string): Promise<BuildDevice | undefined> {
  const document = getRootDeviceDoc(deviceId);
  const result = await getDoc(document);
  return result.data() as BuildDevice | undefined;
}

export async function storeDevice(
  uid: string,
  id: string,
  toStore: DeviceCalibration | Partial<Device>,
): Promise<void> {
  const document = getDeviceDoc(uid, id);
  return setDocWithLogging(document, toStore, { merge: true });
}

export async function loadDevice(uid: string, id: string): Promise<Device | undefined> {
  const document = getDeviceDoc(uid, id);
  const result = await getDoc(document);
  return result.data();
}

export async function loadDevices(uid: string): Promise<Map<string, Device>> {
  const devices = getDevicesCollection(uid);
  const q = query(devices, limit(100));
  const querySnap = await getDocs(q);
  const { docs } = querySnap;
  return new Map(docs.map((obj) => [obj.id, obj.data()]));
}

export const mphToMps = 0.44704;
export const radPerSecToRpm = 60 / (2 * Math.PI);
export const POWER_USER_ID = "tQjT8wO02CVVhBia0gCMaYR3Xpc2";
export const MICHAEL_USER_ID = "quZSnZ8lKLSVEeBh8A9EpfL8cjv1";
export const COACH_CHRIS_USER_ID = "F5EL4dyUL1RXGvOieBhWb3LykW32";

export const ADMIN_USER_IDS = new Map<string, boolean>([
  ["tQjT8wO02CVVhBia0gCMaYR3Xpc2", true], // carrino
  ["quZSnZ8lKLSVEeBh8A9EpfL8cjv1", true], // michael
  ["kt4e2j9PpNZgkwUJwEYm46vxNNk1", true], // brennan
]);
export const DEVICES_USER_IDS = new Map<string, boolean>([
  ["SlVtBCjSQOccsb5FGywvPL3PvYn2", true], // rob.mueller@cargt.com
  ["VrBMkaytGbZrzm5gxmMgmITDnJn2", true], // megan.robertson@cargt.com
  ["X2ZUNZf40MNAwyO19UfM5SdkI2H3", true], // Jacob @ TD
  ["qoqCdiqSjefsR2bjkRiaDi511y52", true], // Cooper @ TD
  ["kt4e2j9PpNZgkwUJwEYm46vxNNk1", true], // Brennan @ TD (for development)
]);

// admin users can see any user's data.
// also write any user's data.
ADMIN_USER_IDS.forEach((value, key) => {
  DEVICES_USER_IDS.set(key, value);
});

// power users have access to all features including dev features.
export const POWER_USER_IDS = new Map<string, boolean>([]);
ADMIN_USER_IDS.forEach((value, key) => {
  POWER_USER_IDS.set(key, value);
});

export const EMAIL_USER_IDS = new Map<string, boolean>([
  ["ivaFJlXtziZ0XVFgpKrBsoq6VNM2", true], // chase
]);
ADMIN_USER_IDS.forEach((value, key) => {
  EMAIL_USER_IDS.set(key, value);
});

// Beta users get access to new features that are almost ready to ship.
export const BETA_USER_IDS = new Map<string, boolean>([
  // ["9p2ZMXQgnFhctQOnB9VLgPIz1x22", true], // mikey @ overthrow
  // ["wg9vLKmgykh3XVrNFpEfGNBuc2F3", true], // josh @ overthrow
  // ["zNkNVF8XI2a8lUeslWbBhDP9ulK2", true], // Robbie C
  // [LIVE_USER_ID, true],
]);
POWER_USER_IDS.forEach((value, key) => {
  BETA_USER_IDS.set(key, value);
});

export function advanceRatio(summary?: ThrowMetrics, diameter = 0.211): number {
  return summary
    ? div(
        mul(mul(diameter, Math.abs(summary?.rotPerSec ?? 0)), Math.PI),
        mul(mphToMps, summary?.speedMph || 1),
      ).toNumber()
    : NaN;
}
