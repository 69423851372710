import {
  DocumentData,
  FirestoreDataConverter,
  QueryDocumentSnapshot,
  SnapshotOptions,
} from "firebase/firestore";
import { LeaderboardEntry, LeaderboardEntryWithId } from "../../model/leaderboard";

export const leaderboardConverter: FirestoreDataConverter<LeaderboardEntryWithId> = {
  fromFirestore: (
    snapshot: QueryDocumentSnapshot<LeaderboardEntryWithId>,
    options: SnapshotOptions,
  ): LeaderboardEntryWithId => {
    const data = snapshot.data(options);

    return { ...data, id: snapshot.id } as LeaderboardEntryWithId; // Adjust this mapping as necessary
  },
  toFirestore: (modelObject: LeaderboardEntryWithId): DocumentData => {
    const document: DocumentData = { ...modelObject };
    delete document.id;
    return document;
  },
};
