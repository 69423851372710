import { useDocumentData } from "react-firebase-hooks/firestore";
import { getLeaderboardStoreMetadata, saveLeaderboardStoreMetadata } from "../summaryUtils";
import { useMemo } from "react";
import {
  StoreMetadata,
  leaderboardStoreMetadataConverter,
} from "../firebase/converters/leaderboardStoreMetadata";

export const useStoreMetadata = (userId?: string): [StoreMetadata | null | undefined, boolean] => {
  const [leaderboardMetadata, isLeaderboardMetadataLoading] = useDocumentData<StoreMetadata>(
    getLeaderboardStoreMetadata(userId)?.withConverter(leaderboardStoreMetadataConverter),
  );

  return useMemo(
    () => [isLeaderboardMetadataLoading ? null : leaderboardMetadata, isLeaderboardMetadataLoading],
    [isLeaderboardMetadataLoading, leaderboardMetadata],
  );
};

export const clearStoreAddress = async (userId: string) => {
  await saveLeaderboardStoreMetadata(
    userId,
    {
      address: { addressLines: [] },
      // @ts-ignore
      addressValidity: null,
    },
    false,
  );
};
