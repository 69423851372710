import * as React from "react";
import Typography from "@mui/material/Typography";

interface TitleProps {
  variant?: string;
  children?: React.ReactNode;
}

export default function Title(props: TitleProps) {
  return (
    <Typography
      sx={{
        fontSize: { mobile: "16px", mobileWide: "18px", md: "20px" },
        fontWeight: "medium",
        userSelect: "none",
        color: (theme) =>
          props.variant === "secondary"
            ? theme.palette.secondary.light
            : theme.palette.primary.main,
      }}
      gutterBottom
    >
      {props.children}
    </Typography>
  );
}
