import { CssBaseline, ThemeProvider, createTheme, lighten, styled } from "@mui/material";
import { ReactNode, StrictMode } from "react";
import { MaterialDesignContent, SnackbarProvider } from "notistack";
import { ConfirmProvider } from "material-ui-confirm";
import theme from "../../theme";
import GlobalProvider from "./GlobalProvider";
import i18n from "../../i18next";
import { I18nextProvider } from "react-i18next";
import SplitProvider from "./SplitProvider";

const muiTheme = createTheme(theme);

// styling in here versus sep file now (not working as it was with other styled import)
const StyledMaterialDesignContent = styled(MaterialDesignContent)(({ theme }) => ({
  "&.notistack-MuiContent-info": {
    color: theme.palette.primary.main,
    border: "1px solid",
    borderColor: lighten(theme.palette.primary.main, 0.5),
    backgroundColor: lighten(theme.palette.primary.main, 0.9),
    filter: "drop-shadow(0 4px 3px rgb(0 0 0 / 0.07)) drop-shadow(0 2px 2px rgb(0 0 0 / 0.06))",
    boxShadow: "none",
  },
  "&.notistack-MuiContent-success": {
    color: theme.palette.success.main,
    border: "1px solid",
    borderColor: lighten(theme.palette.success.main, 0.7),
    backgroundColor: lighten(theme.palette.success.main, 0.95),
    filter: "drop-shadow(0 4px 3px rgb(0 0 0 / 0.07)) drop-shadow(0 2px 2px rgb(0 0 0 / 0.06))",
    boxShadow: "none",
  },
  "&.notistack-MuiContent-error": {
    color: theme.palette.error.main,
    border: "1px solid",
    borderColor: lighten(theme.palette.error.main, 0.7),
    backgroundColor: lighten(theme.palette.error.main, 0.95),
    filter: "drop-shadow(0 4px 3px rgb(0 0 0 / 0.07)) drop-shadow(0 2px 2px rgb(0 0 0 / 0.06))",
    boxShadow: "none",
  },
  "&.notistack-MuiContent-warning": {
    color: theme.palette.warning.main,
    border: "1px solid",
    borderColor: lighten(theme.palette.warning.main, 0.7),
    backgroundColor: lighten(theme.palette.warning.main, 0.95),
    filter: "drop-shadow(0 4px 3px rgb(0 0 0 / 0.07)) drop-shadow(0 2px 2px rgb(0 0 0 / 0.06))",
    boxShadow: "none",
  },
}));

const SnackbarStyle = {
  info: StyledMaterialDesignContent,
  success: StyledMaterialDesignContent,
  error: StyledMaterialDesignContent,
  primary: StyledMaterialDesignContent,
  warning: StyledMaterialDesignContent,
};

function AppProviders(props: { children: ReactNode }) {
  return (
    <StrictMode>
      <I18nextProvider i18n={i18n} defaultNS={"common"}>
        <SplitProvider>
          <GlobalProvider>
            <ThemeProvider theme={muiTheme}>
              <ConfirmProvider
                defaultOptions={{
                  confirmationButtonProps: { autoFocus: true },
                }}
              >
                <SnackbarProvider
                  autoHideDuration={3000}
                  maxSnack={3}
                  style={{ width: 500, marginTop: 40, marginBottom: -40 }}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                  Components={SnackbarStyle}
                >
                  {props.children}
                </SnackbarProvider>
              </ConfirmProvider>
            </ThemeProvider>
          </GlobalProvider>
        </SplitProvider>
      </I18nextProvider>
    </StrictMode>
  );
}

export default AppProviders;
