import { FirestoreDataConverter, QueryDocumentSnapshot, SnapshotOptions } from "firebase/firestore";
import { LeaderboardUserMetadata } from "../../model/leaderboard";

export interface UserMetadata {
  name?: string;
  pdgaNumber?: string;
  photo?: string;
  preferredMeasurementSystem?: string;
}
export const leaderboardUserMetadataConverter: FirestoreDataConverter<
  UserMetadata,
  LeaderboardUserMetadata
> = {
  fromFirestore: (
    snapshot: QueryDocumentSnapshot<LeaderboardUserMetadata>,
    options: SnapshotOptions,
  ): UserMetadata => {
    const data = snapshot.data(options);

    const userMetadata: UserMetadata = {
      name: data.displayName,
      pdgaNumber: data.pdgaNumber,
      // "photo" accidentally snuck in here
      photo: data.photo ?? data.photoUrl,
      preferredMeasurementSystem: data.preferredMeasurementSystem,
    };

    return userMetadata;
  },
  toFirestore: (modelObject: UserMetadata): LeaderboardUserMetadata => {
    const toDB = {
      // Cleanup mess from bad converter
      photo: null,
      displayName: modelObject.name,
      pdgaNumber: modelObject.pdgaNumber,
      photoUrl: modelObject.photo,
      preferredMeasurementSystem: modelObject.preferredMeasurementSystem,
    };

    return toDB;
  },
};
