import { ThemeOptions, darken, lighten, PaletteColorOptions } from "@mui/material";
import { Link as RouterLink, LinkProps as RouterLinkProps } from "react-router-dom";
import { LinkProps } from "@mui/material/Link";
import { forwardRef } from "react";

const LinkBehavior = forwardRef<
  HTMLAnchorElement,
  Omit<RouterLinkProps, "to"> & { href: RouterLinkProps["to"] }
>(function LinkBehavior(props, ref) {
  const { href, ...other } = props;
  return <RouterLink ref={ref} to={href} {...other} />;
});

declare module "@mui/material/Button" {
  interface ButtonPropsVariantOverrides {
    primary: true;
    secondary: true;
    menu: true;
    link: true;
    warning: true;
    destructive: true;
    connect: true;
  }
}
declare module "@mui/material/styles" {
  interface PaletteOptions {
    primary?: PaletteColorOptions;
    secondary?: PaletteColorOptions;
    accent?: PaletteColorOptions;
  }
}
declare module "@mui/material/styles" {
  interface BreakpointOverrides {
    xs: false; // TODO: remove the `xs` breakpoint
    sm: true;
    md: true;
    lg: true;
    xl: true;
    mobile: true; // adds the `mobile` breakpoint
    mobileWide: true;
    mobileMax: true;
    tablet: true;
    laptop: true;
    desktop: true;
  }
}

const theme: ThemeOptions = {
  typography: {
    fontFamily: "HCo Gotham SSm, sans-serif",
    fontSize: 14,
    fontWeightLight: 200,
    fontWeightRegular: 300,
    fontWeightMedium: 500,
  },
  palette: {
    primary: {
      main: "#2A81DE",
      light: "#BBDBF9",
      dark: "#1C2127",
      contrastText: "#FFF",
    },
    secondary: {
      light: "#4E5C77",
      main: "#4E5C77",
      dark: "#383836",
      contrastText: "#EEF6FD",
    },
    accent: {
      main: "#f8dd4e",
    },
  },
  breakpoints: {
    values: {
      mobile: 0,
      sm: 600,
      mobileWide: 475,
      mobileMax: 425,
      tablet: 768,
      md: 900,
      laptop: 1024,
      lg: 1200,
      desktop: 1200,
      xl: 1536,
    },
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        h1: ({ theme }) => ({
          fontWeight: 500,
          fontSize: "3.5rem",
        }),
        h2: ({ theme }) => ({
          fontWeight: 400,
          fontSize: "3rem",
        }),
        h3: ({ theme }) => ({
          fontWeight: 400,
          fontSize: "2.6rem",
        }),
        h4: ({ theme }) => ({
          fontWeight: 400,
          fontSize: "2.2rem",
        }),
        h5: ({ theme }) => ({
          fontWeight: 400,
          fontSize: "1.8rem",
        }),
        h6: ({ theme }) => ({
          fontWeight: 400,
          fontSize: "1.4rem",
        }),
      },
    },
    MuiAlert: {
      styleOverrides: {
        message: {
          lineHeight: "1.5rem",
        },
      },
    },
    MuiAlertTitle: {
      styleOverrides: {
        root: ({ theme }) => ({
          marginBottom: theme.spacing(2),
        }),
      },
    },
    MuiLink: {
      defaultProps: {
        component: LinkBehavior,
      } as LinkProps,
    },
    MuiButtonBase: {
      defaultProps: {
        LinkComponent: LinkBehavior,
      },
      styleOverrides: {
        root: {
          borderRadius: "2px",
          "& .MuiButton-icon": {
            marginRight: 0.5,
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        outlined: ({ theme, ownerState }) => ({
          fontSize: "1rem",
          ...(ownerState.size === "small" && {
            padding: "4px 8px",
            fontSize: "0.9rem",
            gap: 0.5,
          }),
          gap: 1,
          border: "1px solid",
          textTransform: "none",
          borderColor: ownerState.color ?? theme.palette.primary.main,
          backgroundColor: "white",
          "&:hover": {
            color: ownerState.color ?? theme.palette.primary.main,
          },
        }),
      },
      variants: [
        {
          props: { variant: "primary" },
          style: ({ theme, ownerState }) => ({
            ...(ownerState.size === "small" && {
              padding: "4px 8px",
              fontSize: "0.9rem",
              gap: 0.5,
            }),
            gap: 1,
            color: "white",
            backgroundColor: theme.palette.primary.main,
            borderColor: darken(theme.palette.primary.main, 0.2),
            textTransform: "none",
            "&:hover": {
              color: "white",
              backgroundColor: darken(theme.palette.primary.main, 0.2),
            },
            "&:disabled": {
              color: "white",
              backgroundColor: lighten(theme.palette.primary.main, 0.6),
            },
          }),
        },
        {
          props: { variant: "menu" },
          style: ({ theme, ownerState }) => ({
            ...(ownerState.size === "small" && {
              padding: "4px 8px",
              fontSize: "12px",
              "& > svg": {
                width: "20px",
                height: "20px",
              },
              [theme.breakpoints.down("sm")]: {
                fontSize: "0.7rem",
                "& > svg": {
                  width: "16px",
                  height: "16px",
                },
              },
            }),
            fontSize: "14px",
            border: "1px solid rgba(0, 0, 0, 0)",
            display: "flex",
            flexDirection: "row",
            gap: "4px",
            color: theme.palette.common.white,
            textTransform: "none",
            alignItems: "center",
            "&:hover": {
              borderColor: "rgba(255, 255, 255, 0.5)",
              color: "inherit",
              "& > svg": {
                color: "auto",
              },
            },
            [theme.breakpoints.down("md")]: {
              fontSize: "0.85rem",
            },
          }),
        },
        {
          props: { variant: "connect" },
          style: ({ theme, ownerState }) => ({
            fontSize: "1rem",
            border: "1px solid rgba(0, 0, 0, 0)",
            display: "flex",
            flexDirection: "row",
            gap: "4px",
            color: theme.palette.primary.light,
            backgroundColor: theme.palette.primary.dark,
            textTransform: "none",
            alignItems: "center",
            fontWeight: 500,
            [theme.breakpoints.down("sm")]: {
              fontSize: "0.7rem",
              "& > svg": {
                width: "16px",
                height: "16px",
              },
            },
            padding: "8px 12px",
            ...(ownerState.size === "small" && {
              padding: "4px 8px",
              fontSize: "0.9rem",
              "& > svg": {
                width: "20px",
                height: "20px",
              },
            }),
            "&:hover": {
              backgroundColor: theme.palette.grey[900],
              color: "white",
              "& > svg": {
                color: "white",
              },
            },
            [theme.breakpoints.down("md")]: {
              fontSize: "0.85rem",
            },
          }),
        },
        {
          props: { variant: "secondary" },
          style: ({ theme }) => ({
            color: theme.palette.grey[800],
            textTransform: "none",
            alignItems: "center",
            "&:hover": {
              backgroundColor: theme.palette.grey[200],
            },
          }),
        },
        {
          props: { variant: "link" },
          style: ({ theme }) => ({
            color: theme.palette.grey[800],
            fontSize: "1rem",
            textTransform: "none",
            "&:hover": {
              color: theme.palette.primary.main,
              backgroundColor: "rgba(0, 0, 0, 0)",
            },
          }),
        },
        {
          props: { variant: "warning" },
          style: ({ theme }) => ({
            color: theme.palette.warning.contrastText,
            fontSize: "1rem",
            textTransform: "none",
            backgroundColor: theme.palette.warning.main,
            "&:hover": {
              backgroundColor: theme.palette.warning.contrastText,
              color: theme.palette.warning.main,
            },
          }),
        },
        {
          props: { variant: "destructive" },
          style: ({ theme, ownerState }) => ({
            ...(ownerState.size === "small" && {
              padding: "4px 8px",
              fontSize: "0.9rem",
              gap: 0.5,
              lineHeight: "inherit",
            }),
            border: "1px solid",
            color: theme.palette.error.main,
            fontSize: "1rem",
            textTransform: "none",
            backgroundColor: theme.palette.error.contrastText,
            borderColor: theme.palette.error.main,
            "&:hover": {
              backgroundColor: theme.palette.error.main,
              color: theme.palette.error.contrastText,
            },
          }),
        },
      ],
    },
  },
};

export default theme;
