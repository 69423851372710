import { useEffect, useRef, useState } from "react";
import {
  FormControlLabel,
  FormGroup,
  Paper,
  Typography,
  IconButton,
  Switch,
  SxProps,
  TextField,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { format } from "date-fns";
import { BuildDevice } from "../model/device";
import { getTrueUserId, storeBuildDevice } from "../summaryUtils";
import useNotify from "../hooks/useNotify";
import { DeviceReading } from "../model/calibrate";
import useNotify from "../hooks/useNotify";

export function DeviceComputedFields(props: { device: BuildDevice }) {
  const hzDiff = Math.abs((props.device.sensorHz ?? 0) - 8000);
  const hzColor = hzDiff < 16 ? "" : hzDiff < 20 ? "yellow" : "red";
  let battColor: string | undefined = undefined;
  if (props.device.adcBatteryVoltage && props.device.factoryBatteryVoltage) {
    const diff = Math.abs(props.device.adcBatteryVoltage - props.device.factoryBatteryVoltage);
    if (diff > 0.1) {
      battColor = diff > 0.2 ? "red" : "yellow";
    }
  }
  return (
    <>
      <Paper
        sx={{
          p: 2,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Paper
          elevation={3}
          sx={{
            p: 2,
            display: "flex",
            padding: "1rem",
            marginBottom: "1rem",
          }}
        >
          <Typography variant="subtitle1" color="textSecondary" style={{ marginRight: "1rem" }}>
            UID
          </Typography>
          <Typography variant="body1">{props.device.uid}</Typography>
        </Paper>
        <Paper
          elevation={3}
          sx={{
            p: 2,
            display: "flex",
            padding: "1rem",
            marginBottom: "1rem",
          }}
        >
          <Typography variant="subtitle1" color="textSecondary" style={{ marginRight: "1rem" }}>
            MAC
          </Typography>
          <Typography variant="body1">{props.device.mac}</Typography>
        </Paper>
        <Paper
          elevation={3}
          sx={{
            p: 2,
            display: "flex",
            padding: "1rem",
            marginBottom: "1rem",
          }}
        >
          <Typography
            variant="subtitle1"
            color="textSecondary"
            style={{
              marginRight: "1rem",
              backgroundColor: hzColor,
            }}
          >
            Sensor Hz:
          </Typography>
          <Typography variant="body1">{props.device.sensorHz?.toFixed(1)}</Typography>
        </Paper>
        <Paper
          elevation={3}
          sx={{
            p: 2,
            display: "flex",
            padding: "1rem",
            marginBottom: "1rem",
          }}
        >
          <Typography
            variant="subtitle1"
            color="textSecondary"
            style={{
              marginRight: "1rem",
              backgroundColor: battColor,
            }}
          >
            Calculated Battery Voltage:
          </Typography>
          <Typography variant="body1">{props.device.adcBatteryVoltage?.toFixed(3)}</Typography>
        </Paper>
        <Paper
          elevation={3}
          sx={{
            p: 2,
            display: "flex",
            padding: "1rem",
            marginBottom: "1rem",
          }}
        >
          <Typography
            variant="subtitle1"
            color="textSecondary"
            style={{
              marginRight: "1rem",
              backgroundColor: props.device.wcoSwitchoverTime ? undefined : "red",
            }}
          >
            {"Oscillator " +
              (props.device.wcoSwitchoverTime
                ? "successful: " + format(props.device.wcoSwitchoverTime, "MM/dd/yyyy HH:mm:ss")
                : "not active")}
          </Typography>
        </Paper>
        <Paper
          elevation={3}
          sx={{
            p: 2,
            display: "flex",
            padding: "1rem",
            marginBottom: "1rem",
            backgroundColor: props.device.cc1ChargingTime ? undefined : "yellow",
          }}
        >
          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  checked={!!props.device.cc1ChargingTime}
                  disabled={true}
                  name="cc1Charging"
                  color="primary"
                />
              }
              label={
                props.device.cc1ChargingTime
                  ? "Charged at " + props.device.cc1ChargingVolts?.toFixed(3) + " Volts."
                  : "Has not charged while connected. (after depanelize)"
              }
            />
          </FormGroup>
        </Paper>
        <Paper
          elevation={3}
          sx={{
            p: 2,
            display: "flex",
            padding: "1rem",
            marginBottom: "1rem",
            backgroundColor: props.device.cc2ChargingTime ? undefined : "yellow",
          }}
        >
          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  checked={!!props.device.cc2ChargingTime}
                  disabled={true}
                  name="cc2Charging"
                  color="primary"
                />
              }
              label={
                props.device.cc2ChargingTime
                  ? "Charged at " + props.device.cc2ChargingVolts?.toFixed(3) + " Volts."
                  : "Has not charged with cable reversed. (after depanelize)"
              }
            />
          </FormGroup>
        </Paper>
      </Paper>
    </>
  );
}

export function DeviceStaticFields(props: { device: BuildDevice; lastReading?: DeviceReading }) {
  const [showSnackbar, setShowSnackbar] = useState(false);
  const storeDevice = (data: Partial<BuildDevice>) => storeBuildDevice(props.device.uid, data);

  let vinColor: string | undefined = undefined;
  let battBackground = undefined;
  let compareVoltage = 3.4;
  if (props.device.factoryBatteryVoltage) {
    if (
      props.device.factoryBatteryVoltage > 4.2 ||
      props.device.factoryBatteryVoltage < compareVoltage
    ) {
      battBackground = "red";
    }
    compareVoltage = props.device.factoryBatteryVoltage;
  }
  if (props.device.factoryVinVoltage) {
    if (
      props.device.factoryVinVoltage > 4.2 ||
      props.device.factoryVinVoltage < compareVoltage - 0.1
    ) {
      vinColor = "red";
    }
  }
  let vddColor: string | undefined = undefined;
  if (props.device.factoryVddVoltage) {
    if (Math.abs(props.device.factoryVddVoltage - 3.3) > 0.05) {
      vddColor = "red";
    }
  }

  let currentColor: string | undefined = undefined;
  if (props.device.currentDrawMA) {
    if (props.device.currentDrawMA > 13 || props.device.currentDrawMA < 10) {
      currentColor = "red";
    }
  }

  return (
    <>
      <Paper
        sx={{
          p: 2,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Paper
          elevation={3}
          sx={{
            p: 2,
            display: "flex",
            padding: "1rem",
            marginBottom: "1rem",
          }}
        >
          <SingleFieldTextBox<BuildDevice>
            input={props.device}
            allowEmpty={false}
            fieldName={"serialNumber"}
            storeData={storeDevice}
          />
        </Paper>
        <Paper
          elevation={3}
          sx={{
            p: 2,
            display: "flex",
            padding: "1rem",
            marginBottom: "1rem",
            backgroundColor: currentColor,
          }}
        >
          <SingleFieldTextBox<BuildDevice>
            input={props.device}
            allowEmpty={false}
            label={"Current (mA)"}
            fieldName={"currentDrawMA"}
            storeData={(data) => {
              const toStore = data.currentDrawMA ? Number(data.currentDrawMA) : null;
              return storeDevice({ currentDrawMA: toStore });
            }}
          />
        </Paper>
        <Paper
          elevation={3}
          sx={{
            p: 2,
            display: "flex",
            padding: "1rem",
            marginBottom: "1rem",
            backgroundColor: battBackground,
          }}
        >
          <SingleFieldTextBox<BuildDevice>
            allowEmpty={false}
            input={props.device}
            label={"Battery Voltage"}
            fieldName={"factoryBatteryVoltage"}
            storeData={(data) => {
              const storeNumber = data.factoryBatteryVoltage
                ? Number(data.factoryBatteryVoltage)
                : null;
              let toStore: Partial<BuildDevice> = {
                factoryBatteryVoltage: storeNumber,
              };
              if (props.lastReading?.battVolts) {
                toStore = { ...toStore, adcBatteryVoltage: props.lastReading.battVolts };
              }

              return storeDevice(toStore);
            }}
          />
        </Paper>
        <Paper
          elevation={3}
          sx={{
            p: 2,
            display: "flex",
            padding: "1rem",
            marginBottom: "1rem",
            backgroundColor: vinColor,
          }}
        >
          <SingleFieldTextBox<BuildDevice>
            allowEmpty={false}
            input={props.device}
            label={"VIN Voltage"}
            fieldName={"factoryVinVoltage"}
            storeData={(data) => {
              return storeDevice({ factoryVinVoltage: Number(data.factoryVinVoltage) });
            }}
          />
        </Paper>
        <Paper
          elevation={3}
          sx={{
            p: 2,
            display: "flex",
            padding: "1rem",
            marginBottom: "1rem",
            backgroundColor: vddColor,
          }}
        >
          <SingleFieldTextBox<BuildDevice>
            allowEmpty={false}
            input={props.device}
            label={"VDD Voltage"}
            fieldName={"factoryVddVoltage"}
            storeData={(data) => {
              return storeDevice({ factoryVddVoltage: Number(data.factoryVddVoltage) });
            }}
          />
        </Paper>
        <Paper
          elevation={3}
          sx={{
            p: 2,
            display: "flex",
            padding: "1rem",
            marginBottom: "1rem",
            backgroundColor: props.device.ledGreen === false ? "red" : undefined,
          }}
        >
          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  checked={!!props.device.ledGreen}
                  onChange={(e) => {
                    return storeDevice({ ledGreen: !!e.target.checked });
                  }}
                  name="isGreen"
                  color="primary"
                />
              }
              label={
                props.device.ledGreen === undefined
                  ? "Is the light green when powered on?"
                  : props.device.ledGreen
                    ? "Green LED works!"
                    : "Light is not green when powered on"
              }
            />
          </FormGroup>
        </Paper>
        <Paper
          elevation={3}
          sx={{
            p: 2,
            display: "flex",
            padding: "1rem",
            marginBottom: "1rem",
            backgroundColor: props.device.ledRed === false ? "red" : undefined,
          }}
        >
          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  checked={!!props.device.ledRed}
                  onChange={(e) => {
                    return storeDevice({ ledRed: !!e.target.checked });
                  }}
                  name="isRed"
                  color="primary"
                />
              }
              label={
                props.device.ledRed === undefined
                  ? "Is the light red when charging?  (after depanelize)"
                  : props.device.ledRed
                    ? "Red LED works!"
                    : "Light is not red when charging"
              }
            />
          </FormGroup>
        </Paper>
      </Paper>
    </>
  );
}
export function DeviceEditFields(props: { device: BuildDevice }) {
  const [weight, setWeight] = useState(
    String(props.device.weight ? props.device.weight * 1000.0 : ""),
  );
  const [mold, setMold] = useState(props.device.mold ?? "");
  const [color, setColor] = useState(props.device.color ?? "");
  const storeDevice = (data: Partial<BuildDevice>) => storeBuildDevice(props.device.uid, data);
  return (
    <>
      <Paper
        sx={{
          p: 2,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Paper
          elevation={3}
          sx={{
            p: 2,
            display: "flex",
            padding: "1rem",
            marginBottom: "1rem",
          }}
        >
          <SingleFieldTextBox<BuildDevice>
            allowEmpty={false}
            input={{ ...props.device, weight: props.device.weight && props.device.weight * 1000.0 }}
            label={"Weight (g)"}
            fieldName={"weight"}
            storeData={(data) => {
              const toStore = {
                weight: Number(data.weight) / 1000.0,
                weightUser: getTrueUserId(),
                weightTime: Date.now(),
              };
              return storeDevice(toStore);
            }}
          />
        </Paper>
        <Paper
          elevation={3}
          sx={{
            p: 2,
            display: "flex",
            padding: "1rem",
            marginBottom: "1rem",
          }}
        >
          <SingleFieldTextBox input={props.device} fieldName={"mold"} storeData={storeDevice} />
        </Paper>
        <Paper
          elevation={3}
          sx={{
            p: 2,
            display: "flex",
            padding: "1rem",
            marginBottom: "1rem",
          }}
        >
          <SingleFieldTextBox input={props.device} fieldName={"color"} storeData={storeDevice} />
        </Paper>
        <Paper
          elevation={3}
          sx={{
            p: 2,
            display: "flex",
            padding: "1rem",
            marginBottom: "1rem",
          }}
        >
          <SingleFieldTextBox
            input={props.device}
            fieldName={"orderNumber"}
            storeData={(data) => {
              const toStore: Partial<BuildDevice> = {
                orderNumber: data.orderNumber,
                orderNumberUser: getTrueUserId(),
                orderNumberTime: Date.now(),
              };
              return storeDevice(toStore);
            }}
          />
        </Paper>
        <Paper
          elevation={3}
          sx={{
            p: 2,
            display: "flex",
            padding: "1rem",
            marginBottom: "1rem",
          }}
        >
          <SingleFieldTextBox
            input={props.device}
            fieldName={"customerName"}
            storeData={storeDevice}
          />
        </Paper>
        <Paper
          elevation={3}
          sx={{
            p: 2,
            display: "flex",
            padding: "1rem",
            marginBottom: "1rem",
          }}
        >
          <SingleFieldTextBox
            input={props.device}
            fieldName={"notes"}
            multiLine={true}
            storeData={storeDevice}
          />
        </Paper>
      </Paper>
    </>
  );
}

type StringKeys<T> = {
  [K in keyof T]: T[K] extends string ? K : never;
};

export function SingleFieldTextBox<T = BuildDevice>(props: {
  input: T;
  fieldName: keyof T;
  storeData: (data: Partial<T>) => Promise<void>;
  renderBody?: () => JSX.Element;
  multiLine?: boolean;
  allowEmpty?: boolean;
  label?: string;
  sx?: SxProps;
}) {
  const notify = useNotify();
  const existingData = String(props.input[props.fieldName] ?? "");
  const allowEmpty = props.allowEmpty ?? true;
  const [editing, setEditing] = useState(!existingData);
  const [value, setValue] = useState(existingData);
  const [previousState, setPreviousState] = useState(existingData);
  const textRef = useRef<HTMLElement>();

  useEffect(() => {
    if (previousState !== existingData) {
      setEditing(!existingData);
      setValue(existingData);
      setPreviousState(existingData);
    }
  }, [previousState, existingData]);

  // useEffect(() => {
  //   if (existingData != value && (!editing || !value)) {
  //     setValue(existingData);
  //   }
  // }, [existingData, editing, value]);

  const showPlainRender = !editing && props.renderBody;
  return (
    <>
      {!showPlainRender && (
        <TextField
          inputRef={textRef}
          sx={{ minWidth: "20rem", ...props.sx }}
          multiline={props.multiLine ?? false}
          label={props.label ?? props.fieldName.charAt(0).toUpperCase() + props.fieldName.slice(1)}
          variant="standard"
          disabled={!editing}
          value={editing ? value : existingData}
          // use focus lost to store
          onBlur={(e) => {
            const newVal = e.target.value;
            if (newVal == null || newVal == existingData) {
              setValue(existingData);
              setEditing(!existingData);
              return;
            }

            if (!allowEmpty && !newVal) {
              setValue(existingData);
              setEditing(!existingData);
              return;
            }

            setValue(newVal);
            setEditing(!newVal);
            props
              .storeData({ [props.fieldName]: newVal })
              .then(() => notify("info", "Saved successfully!"));
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter" || e.key === "Tab") {
              textRef.current?.blur?.();
            }
          }}
          onChange={(e) => {
            setValue(e.target.value);
          }}
        />
      )}
      {showPlainRender && props.renderBody?.()}
      {!editing && (
        <IconButton
          // make even smaller
          sx={{ padding: 0, marginLeft: "0.4rem", alignSelf: "flex-start" }}
          color={"primary"}
          size={"small"}
          onClick={() => {
            setValue(existingData);
            setEditing(true);
          }}
        >
          <EditIcon sx={{ fontSize: "20px" }} />
          {/*<EditIcon />*/}
        </IconButton>
      )}
    </>
  );
}
