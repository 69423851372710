import { getAdminUserRolesCollection, getUserAdminCollection } from "@/firebase/collections";
import { firebaseApp } from "@/firebaseConfig";
import type { UserRole } from "@/types/user";
import type { User } from "firebase/auth";
import { doc, getFirestore, runTransaction } from "firebase/firestore";
import { useEffect, useState } from "react";

export const useUserRoles = (user: User | null | undefined) => {
  const [userRoles, setUserRoles] = useState<UserRole[]>([]);

  useEffect(() => {
    if (!user || !user.uid) {
      return;
    }
    const fetchUserRoles = async () => {
      const userRoles = await runTransaction(getFirestore(firebaseApp), async (transaction) => {
        const roles = [];
        const userAdminDoc = await transaction.get(doc(getUserAdminCollection(), user.uid));
        if (userAdminDoc.exists()) {
          const roleIds = userAdminDoc.data()?.roles;

          for (const roleId of roleIds) {
            const roleDoc = await transaction.get(doc(getAdminUserRolesCollection(), roleId));
            if (roleDoc.exists()) {
              roles.push(roleDoc.data() as UserRole);
            }
          }
        }
        return roles?.filter((role) => !role.external) || [];
      });
      setUserRoles(userRoles);
    };
    fetchUserRoles();
  }, [setUserRoles, user]);

  return { userRoles };
};
