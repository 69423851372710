import { Quaternion, Vector3 } from "three";
import { FEET_TO_METERS } from "./3dUtils";
import { mphToMps } from "./summaryUtils";

export class DiscStats {
  hyzer: number = 0; // from -180 to 180, abs(hyzer) > 90 means it is upside down
  uphill: number = 0;
  noseUp: number = 0;
  v: number = 0;

  wx: number = 0;
  wy: number = 0;
  wz: number = 0;

  x: number = 0;
  y: number = 0;
  z: number = 0;

  // spin: number = 0;

  public computeStats(
    p: Vector3,
    dpdt: Vector3,
    v: Vector3,
    q: Quaternion,
    isNegSpin: boolean,
    w: Vector3 | undefined = undefined,
  ) {
    this.v = dpdt.length() / mphToMps;
    v = v.clone().normalize();

    if (w) {
      this.wx = w.x;
      this.wy = w.y;
      this.wz = (w.z * 60) / (2 * Math.PI); // units of rpm
    }
    this.x = p.x / FEET_TO_METERS;
    this.y = p.y / FEET_TO_METERS;
    this.z = -p.z / FEET_TO_METERS;

    const plusZ = new Vector3(0, 0, 1);

    this.uphill = (Math.acos(v.dot(plusZ)) / Math.PI) * 180 - 90;
    let discBot: Vector3 = plusZ.clone().applyQuaternion(q);
    const dot = discBot.dot(v);
    this.noseUp = 90 - (Math.acos(dot) / Math.PI) * 180;
    if (Math.abs(this.noseUp) == 90) {
      this.hyzer = 0;
      return;
    }
    const noseUpAxis = new Vector3().crossVectors(discBot, v).normalize();
    const bringNoseInLine = new Quaternion().setFromAxisAngle(
      noseUpAxis,
      (-this.noseUp * Math.PI) / 180,
    );
    discBot = discBot.applyQuaternion(bringNoseInLine);
    const upsideDown = discBot.z < 0;
    if (Math.abs(v.z) == 1) {
      this.hyzer = 0;
      return;
    }
    let fullHyzer = new Vector3().crossVectors(plusZ, v).normalize();
    if (upsideDown) {
      fullHyzer = fullHyzer.multiplyScalar(-1);
    }
    const dotHyzer = discBot.dot(fullHyzer);
    this.hyzer = -90 + (Math.acos(dotHyzer) / Math.PI) * 180;
    if (upsideDown) {
      this.hyzer = DiscStats.flipHyzer(this.hyzer);
      this.hyzer *= -1;
    }

    if (isNegSpin) {
      // if forehand, then hyzerAngle is reversed
      this.hyzer *= -1;
    }
    this.hyzer *= -1;
  }

  makeUserFriendlyHyzer(): void {
    if (Math.abs(this.hyzer) > 90) {
      this.hyzer = DiscStats.flipHyzer(-this.hyzer);
    }
  }

  static flipHyzer(hyzerDegrees: number): number {
    if (hyzerDegrees <= 0) {
      // final range for hyzer is -180 to 180
      return 180 + hyzerDegrees;
    } else {
      // final range for hyzer is -180 to 180
      return -180 + hyzerDegrees;
    }
  }

  static flipUpsideDown(hyzerDegrees: number): number {
    if (hyzerDegrees <= 0) {
      // final range for hyzer is -180 to 180
      return -180 - hyzerDegrees;
    } else {
      // final range for hyzer is -180 to 180
      return 180 - hyzerDegrees;
    }
  }

  static isUpsideDown(hyzerDegrees: number): boolean {
    return Math.abs(hyzerDegrees) > 90;
  }
}
