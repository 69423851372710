import type { UserAdminDocument, UserAdminInfo } from "@/types/user";
import type {
  FirestoreDataConverter,
  QueryDocumentSnapshot,
  SnapshotOptions,
} from "firebase/firestore";

export const userAdminConverter: FirestoreDataConverter<UserAdminInfo> = {
  fromFirestore: (
    snapshot: QueryDocumentSnapshot<UserAdminInfo>,
    options: SnapshotOptions,
  ): UserAdminInfo => {
    const data = snapshot.data(options);

    return { ...data, id: snapshot.id } as UserAdminInfo; // Adjust this mapping as necessary
  },
  toFirestore: (modelObject: UserAdminInfo): UserAdminDocument => {
    const { id, ...userAdminDoc } = modelObject;
    return { ...userAdminDoc };
  },
};
