export const TECHDISC_COLOR = {
  BLUE: "#2A81DE",
  LIGHT_BLUE: "#BBDBF9",
  DARK_BLUE: "#225EB9",
  DARKER_BLUE: "#14143D",
  GREEN: "#ACE06C",
  LIGHT_GREEN: "#E3F4CC",
  DARK_GREEN: "#63A034",
  PINK: "#E775F5",
  DARK_GREY: "#3E3E3C",
  LIGHT_GREY: "#BFBFBF",
  RED: "#FF4545",
  LIGHT_RED: "#FFCBD0",
  DARK_RED: "#D70018",
  WHITE: "#FBFBFB",
};
