import { useDocumentData } from "react-firebase-hooks/firestore";
import { getLeaderboardUserMetadata } from "../summaryUtils";
import { useMemo } from "react";
import {
  UserMetadata,
  leaderboardUserMetadataConverter,
} from "../firebase/converters/leaderboardUserMetadata";

export const useUserMetadata = (userId?: string): [UserMetadata | null | undefined, boolean] => {
  const [leaderboardUserMetadata, isLeaderboardMetadataLoading] = useDocumentData<UserMetadata>(
    getLeaderboardUserMetadata(userId)?.withConverter(leaderboardUserMetadataConverter),
  );

  return useMemo(
    () => [
      isLeaderboardMetadataLoading ? null : leaderboardUserMetadata,
      isLeaderboardMetadataLoading,
    ],
    [isLeaderboardMetadataLoading, leaderboardUserMetadata],
  );
};
