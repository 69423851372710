import { useRef, useState } from "react";
import Popover from "@mui/material/Popover";
import PopupState, { bindPopover, bindTrigger } from "material-ui-popup-state";
import { Device } from "../model/device";
import { CirclePicker } from "react-color";
import { getUserId, storeDevice } from "../summaryUtils";
import { Box, IconButton } from "@mui/material";
import { Circle } from "@mui/icons-material";

interface Props {
  device?: Device;
  deviceId: string;
}

export default function ColorPicker(props: Props) {
  const uid = getUserId();
  // let loadedColor = props.device?.color || '';
  const [color, setColor] = useState(props.device?.color || "");
  const timeoutIdRef = useRef<NodeJS.Timeout>();
  if (!color && props.device?.color) {
    setColor(props.device.color);
  }
  const handleChangeComplete = (c: any) => {
    setColor(c.hex);
    const timeoutId = timeoutIdRef.current;
    if (timeoutId) {
      clearInterval(timeoutId);
    }
    const toStore = { color: c.hex };
    // debounce the updates to the server
    timeoutIdRef.current = setTimeout(() => storeDevice(uid, props.deviceId, toStore), 1000);
  };

  // @ts-ignore
  return (
    <PopupState variant="popover" popupId="colorPickerPopover">
      {(popupState) => (
        <div>
          <IconButton
            style={{ background: "white" }}
            {...bindTrigger(popupState)}
            aria-label="Disc Color"
            title={"Disc Color"}
          >
            <Circle sx={{ color }} />
          </IconButton>
          <Popover
            {...bindPopover(popupState)}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <Box sx={{ p: 2 }}>
              <CirclePicker
                color={color}
                // onChange={(c: any) => setColor(c.hex)}
                onChangeComplete={handleChangeComplete}
              />
            </Box>
          </Popover>
        </div>
      )}
    </PopupState>
  );
}
