import { AlertColor } from "@mui/material";
import { useSnackbar, OptionsObject, SnackbarMessage } from "notistack";
import { VariantType } from "notistack";
import { useCallback } from "react";

/**
 * A custom hook that wraps useSnackbar from notistack, providing a simplified interface
 * for showing notifications with a single function call.
 */
const useNotify = (): (<TVariant extends VariantType = AlertColor>(
  variant: VariantType,
  message: SnackbarMessage,
  options?: OptionsObject<TVariant>,
) => void) => {
  const { enqueueSnackbar } = useSnackbar();
  /**
   * The notify function simplifies the process of displaying a snackbar/notification.
   * It takes a message and optional options including the variant of the snackbar.
   *
   * @param message - The message to be displayed in the snackbar.
   * @param options - Optional settings for the snackbar such as variant and autoHideDuration.
   */
  const notify = useCallback(
    <TVariant extends VariantType = AlertColor>(
      variant: VariantType,
      message: SnackbarMessage,
      options?: OptionsObject<TVariant>,
    ) => {
      enqueueSnackbar(message, { variant, ...options });
    },
    [enqueueSnackbar],
  );

  return notify;
};

export default useNotify;
