import { FirestoreDataConverter, QueryDocumentSnapshot, SnapshotOptions } from "firebase/firestore";
import { DiscPreset, DiscPresetDocumentData } from "../../model/discs";

export const discPresetConverter: FirestoreDataConverter<DiscPreset, DiscPresetDocumentData> = {
  fromFirestore: (
    snapshot: QueryDocumentSnapshot<DiscPresetDocumentData>,
    options: SnapshotOptions,
  ) => {
    const data = snapshot.data(options);

    return { ...data, id: snapshot.id } as DiscPreset; // Adjust this mapping as necessary
  },
  toFirestore: (modelObject: DiscPreset) => {
    const { id, ...documentData } = modelObject;
    return { ...documentData };
  },
};
