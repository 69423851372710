// functions shared amongst the ./analysis folder would like to cleanup and maybe bring what makes sense over to ./charts to be more specific

import { FilterType, TableRenderProps } from "../ThrowTable";
import { CoreStats } from "../../model/CoreStats";
import { ThrowSummary } from "../../model/throwSummary";
import { CoreMetrics } from "../../firebase/converters/analysisSet";
import { t } from "i18next";

export type FilterPropertyType<TData extends CoreStats = CoreMetrics> = keyof TData;

export const typeKeys: Partial<Record<FilterPropertyType<CoreMetrics>, string>> = {
  speedMph: "Speed",
  spinRpm: "Spin",
  wobble: "Wobble",
  hyzerAngle: "Hyzer",
  noseAngle: "Nose",
  launchAngle: "Launch",
};

export const metricTypeKeys: Partial<Record<FilterPropertyType<CoreMetrics>, string>> = {
  speedKmh: "Speed",
  spinRpm: "Spin",
  wobble: "Wobble",
  hyzerAngle: "Hyzer",
  noseAngle: "Nose",
  launchAngle: "Launch",
};

export function passesAllFilters<TData extends CoreStats = CoreMetrics>(
  filters: FilterType[],
  doc: TData,
): boolean {
  return ["primaryType", "throwTime", "distanceFeet", "distanceMeters"]
    .concat(Object.keys(typeKeys))
    .concat(Object.keys(metricTypeKeys))
    .every((type) => passesFiltersOfType(filters, type as FilterPropertyType<TData>, doc));
}

export function passesOtherFilters<TData extends CoreStats = CoreMetrics>(
  filters: TableRenderProps["filters"],
  type: FilterPropertyType<TData>,
  doc: TData,
  skipFilterType?: boolean,
): boolean {
  if (!filters) {
    // base case
    return true;
  }
  return passesAllFilters(
    filters.filter((f) => skipFilterType || f.property !== type),
    doc,
  );
}

export function passesFiltersOfType<TData extends CoreStats = CoreMetrics>(
  filters: FilterType[],
  type: FilterPropertyType<TData>,
  doc: TData,
): boolean {
  if (!filters.some((f) => f.property === type)) {
    // if there are no filters for this type, then it passes.
    return true;
  }
  return filters.some((filter) => {
    if (filter.property !== type) {
      // if the filter is for a different type we need to keep looking.
      return false;
    }
    const value = fetchData(type as keyof TData, doc);
    if (value === filter.bin) {
      return true;
    }
    if (typeof value !== "number") {
      return false;
    }
    return value >= filter.min && value < filter.max;
  });
}

export function formatDate(date: Date): string {
  if (!date || !date.getMonth) {
    return "Date unknown";
  }
  const month = date.getMonth() + 1; // 0 --> 1
  const day = date.getDate();
  let hours = date.getHours();
  const minutes = date.getMinutes();
  const amOrPm = hours >= 12 ? "pm" : "am";
  // hours to 12
  hours = hours % 12 || 12;
  const minutesFixed = minutes <= 9 ? `0${minutes}` : minutes;
  return `${month}/${day} ${hours}:${minutesFixed}${amOrPm}`;
}

export function fetchData<TData extends CoreStats = ThrowSummary>(type: keyof TData, doc: TData) {
  if (type === "throwTime") {
    return doc.throwTime.toDate().getTime();
  } else if ((type as string).endsWith("Angle")) {
    return doc[type] as number;
  } else if (type === "primaryType") {
    return doc[type] as string;
  }

  return doc[type];
}

export function getMetricLabel<TData extends CoreStats = CoreMetrics>(
  type: FilterPropertyType<TData>,
) {
  if (type === "throwTime") {
    return t("throwTime"); // hmm
  } else if (type === "speedMph") {
    return t("units.mph.upper");
  } else if (type === "speedKmh") {
    return t("units.kmh.upper");
  } else if (type === "rotPerSec" || type === "spinRpm") {
    return t("units.rpm.upper");
  } else if (type === "distanceFeet") {
    return t("units.feet.upper");
  } else if (type === "distanceMeters") {
    return t("units.meters.upper");
  } else {
    return t("units.degrees.upper");
  }
}

export function getTitle<TData extends CoreStats = CoreMetrics>(type: FilterPropertyType<TData>) {
  if (type === "throwTime") {
    return t("throwTime");
  } else if (type === "advanceRatio") {
    return `${t("metrics.advanceRatio.short")} (${t("units.degrees.upper")})`;
  } else if (type === "speedMph") {
    return `${t("metrics.speed.full")} (${t("units.mph.upper")})`;
  } else if (type === "speedKmh") {
    return `${t("metrics.speed.full")} (${t("units.kmh.upper")})`;
  } else if (type === "rotPerSec" || type === "spinRpm") {
    return `${t("metrics.spin.full")} (${t("units.rpm.upper")})`;
  } else if (type === "correctedNoseAngle" || type === "noseAngle") {
    return `${t("metrics.nose.full")} (${t("units.degrees.upper")})`;
  } else if (type === "correctedHyzerAngle" || type === "hyzerAngle") {
    return `${t("metrics.hyzer.full")} (${t("units.degrees.upper")})`;
  } else if (type === "offAxisDegrees" || type === "wobble") {
    return `${t("metrics.wobble.full")} (${t("units.degrees.upper")})`;
  } else if (type === "uphillAngle" || type === "launchAngle") {
    return `${t("metrics.launch.full")} (${t("units.degrees.upper")})`;
  } else if (type === "distanceFeet") {
    return `${t("metrics.distance.full")} (${t("units.feet.upper")})`;
  } else if (type === "distanceMeters") {
    return `${t("metrics.distance.full")} (${t("units.meters.upper")})`;
  } else {
    throw new Error("invalid type");
  }
}

// for adding opacity in an rgba
export function hexToRgb(hex: string) {
  // takes in hex and returns an object of RGB
  hex = hex.replace(/^#/, "");
  if (hex.length === 3) {
    hex = hex.replace(/(.)/g, "$1$1");
  }
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);
  return { r, g, b };
}
