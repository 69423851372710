import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { DRIVER_SCENE_NAME, loadDiscModel } from "./3dUtils";
import { calibrateQuaternionUuid, calibrateServiceUuid } from "./bleConsts";

async function subscribeToQuaternionUpdates(
  bleServer: any,
  handleNotification: (event: any) => void,
) {
  const service = await bleServer.getPrimaryService(calibrateServiceUuid);
  const quaternionChar = await service.getCharacteristic(calibrateQuaternionUuid);
  await quaternionChar.startNotifications();
  return quaternionChar.addEventListener("characteristicvaluechanged", handleNotification);
}

function parseQ(data: DataView): THREE.Quaternion {
  const w = data.getFloat32(0, true);
  const x = data.getFloat32(4, true);
  const y = data.getFloat32(8, true);
  const z = data.getFloat32(12, true);
  return new THREE.Quaternion(x, y, z, w);
}

export async function renderDebugOrientation(
  bleServer: any,
  canvas: Element,
  setQuaternion?: (q: THREE.Quaternion) => void,
) {
  THREE.Cache.enabled = true;
  const scene = new THREE.Scene();
  // threejs uses y as up for some reason
  // this rotation allows us to treat everything as -Z as up aka NED coordinates
  scene.rotation.x = Math.PI / 2;

  // @ts-ignore
  const camera = new THREE.PerspectiveCamera(45, canvas.width / canvas.height, 0.1, 10000);
  // const camera = new THREE.PerspectiveCamera(45, 1, 0.1, 10000);
  const renderer = new THREE.WebGLRenderer({ canvas });
  renderer.shadowMap.enabled = true;

  const controls = new OrbitControls(camera, renderer.domElement);

  camera.position.set(0.5, 0, 0);
  controls.update();
  camera.lookAt(0, 0, 0);
  controls.update();
  scene.add(new THREE.AmbientLight(0x606060));
  const directionalLight = new THREE.DirectionalLight(0xffffff);
  directionalLight.position.set(0, 0, -200);
  scene.add(directionalLight);

  const discModel = await loadDiscModel();
  scene.add(discModel);

  const pointLight = new THREE.PointLight(0xbfbfbf, 0.5, 2, 2);
  pointLight.position.set(0.22, 0, 0.1);
  discModel.add(pointLight);

  const pointLight2 = new THREE.PointLight(0xbfbfbf, 0.5, 2, 2);
  pointLight2.position.set(0.22, 0, -0.1);
  discModel.add(pointLight2);

  // const bulbGeometry = new THREE.SphereGeometry( 0.02, 16, 8 );
  // let bulbLight = new THREE.PointLight( 0xffee88, 1, 100, 2 );
  //
  // let bulbMat = new THREE.MeshStandardMaterial( {
  //     emissive: 0xffffee,
  //     emissiveIntensity: 1,
  //     color: 0x000000
  // } );
  // bulbLight.add( new THREE.Mesh( bulbGeometry, bulbMat ) );
  // bulbLight.position.set( 0, 2, 0 );
  // bulbLight.castShadow = true;
  // discModel.add(bulbLight);
  // scene.add( bulbLight );

  let q = new THREE.Quaternion(1, 0, 0, 0);
  await subscribeToQuaternionUpdates(bleServer, (event) => {
    const value = event.target.value;
    q = parseQ(value);
    setQuaternion?.(q);
  });

  function animate() {
    requestAnimationFrame(animate);
    controls.update();
    const driverScene = scene.getObjectByName(DRIVER_SCENE_NAME);
    if (driverScene) {
      const newQ = new THREE.Quaternion(0, 0, 0, 1);
      newQ.multiply(q);
      driverScene.quaternion.copy(newQ);
    }
    renderer.render(scene, camera);
  }

  animate();
}
