import { liveEventConverter, type LiveEvent } from "@/firebase/converters/events";
import { simulatorCourseConverter } from "@/firebase/converters/simulatorCourse";
import { simulatorPermissionsConverter } from "@/firebase/converters/simulatorPermissions";
import { userAdminConverter } from "@/firebase/converters/userAdmin";
import { userRolesConverter } from "@/firebase/converters/userRoles";
import type { SimulatorCourse, SimulatorPermission } from "@/types/simulator";
import type { UserAdminInfo, UserRole } from "@/types/user";
import { CollectionReference, collection, getFirestore } from "firebase/firestore";
import { firebaseApp } from "../firebaseConfig";
import { Device, DeviceCalibration } from "../model/device";
import { DiscPreset } from "../model/discs";
import {
  LeaderboardEntryWithId,
  LeaderboardStoreMetadata,
  LeaderboardUserMetadata,
} from "../model/leaderboard";
import { ThrowSummary } from "../model/throwSummary";
import { ThrowAnalysis, throwAnalysisConverter } from "./converters/analysisSet";
import { deviceConverter } from "./converters/device";
import { deviceCalibrationConverter } from "./converters/deviceCalibration";
import { discPresetConverter } from "./converters/discPreset";
import { leaderboardConverter } from "./converters/leaderboard";
import { StockShot, stockShotConverter } from "./converters/stockShot";
import { throwSummaryConverter } from "./converters/throwSummary";
import {
  ADMIN_PATH,
  ADMIN_SIMULATOR_COURSE_PATH,
  ADMIN_SIMULATOR_PERMISSIONS_PATH,
  ADMIN_USERS_ROLES_PATH,
  ANALYSIS_SET_COLLECTION_PATH,
  DEVICES_CALIBRATION_COLLECTION_PATH,
  DEVICES_COLLECTION_PATH,
  DISC_PRESETS_COLLECTION_PATH,
  LEADERBOARD_STORE_METADATA_COLLECTION_PATH,
  LEADERBOARD_USER_METADATA_COLLECTION_PATH,
  LEADERBOARD_WEEKLY_COLLECTION_PATH,
  LIVE_EVENTS_COLLECTION_PATH,
  STOCK_SHOTS_PATH,
  STRIPE_CHECKOUT_SESSION_COLLECTION_PATH,
  THROW_SUMMARY_COLLECTION_PATH,
  USERS_PATH,
  USER_ADMIN_PATH,
  USER_DEVICES_COLLECTION_PATH,
} from "./paths";

export function getUsersCollection(): CollectionReference {
  return collection(getFirestore(firebaseApp), USERS_PATH);
}

export function getAdminCollection(): CollectionReference {
  return collection(getFirestore(firebaseApp), ADMIN_PATH);
}

export function getAdminSimulatorPermissions(): CollectionReference<SimulatorPermission> {
  return collection(getAdminCollection(), ADMIN_SIMULATOR_PERMISSIONS_PATH).withConverter(
    simulatorPermissionsConverter,
  );
}

export function getAdminSimulatorCourseCollection(): CollectionReference<SimulatorCourse> {
  return collection(getAdminCollection(), ADMIN_SIMULATOR_COURSE_PATH).withConverter(
    simulatorCourseConverter,
  );
}

export function getAdminUserRolesCollection(): CollectionReference<UserRole> {
  const firestore = getFirestore(firebaseApp);
  return collection(firestore, ADMIN_USERS_ROLES_PATH).withConverter(userRolesConverter);
}

export function getUserAdminCollection(): CollectionReference<UserAdminInfo> {
  return collection(getFirestore(firebaseApp), USER_ADMIN_PATH).withConverter(userAdminConverter);
}

export function getUserThrowSummaries(uid: string): CollectionReference<ThrowSummary> | null {
  return uid
    ? collection(
        getFirestore(firebaseApp),
        THROW_SUMMARY_COLLECTION_PATH.replace("{uid}", uid),
      ).withConverter(throwSummaryConverter)
    : null;
}

export function getUserDiscPresets(uid: string): CollectionReference<DiscPreset> {
  return collection(
    getFirestore(firebaseApp),
    DISC_PRESETS_COLLECTION_PATH.replace("{uid}", uid),
  ).withConverter(discPresetConverter);
}

export function getUserStockShots(uid: string): CollectionReference<StockShot> | null {
  return uid
    ? collection(getFirestore(firebaseApp), STOCK_SHOTS_PATH.replace("{uid}", uid)).withConverter(
        stockShotConverter,
      )
    : null;
}

export function getAnalysisSetCollection(uid: string): CollectionReference<ThrowAnalysis> | null {
  return uid
    ? collection(
        getFirestore(firebaseApp),
        ANALYSIS_SET_COLLECTION_PATH.replace("{uid}", uid),
      ).withConverter(throwAnalysisConverter)
    : null;
}

export function getDevicesCollection(uid: string): CollectionReference<Device> | null {
  return uid
    ? collection(
        getFirestore(firebaseApp),
        USER_DEVICES_COLLECTION_PATH.replace("{uid}", uid),
      ).withConverter(deviceConverter)
    : null;
}

export function getDevicesRootCollection(): CollectionReference<Device> {
  return collection(getFirestore(firebaseApp), DEVICES_COLLECTION_PATH).withConverter(
    deviceConverter,
  );
}

export function getDevicesCalibrationCollection(
  uid: string,
  deviceId: string,
): CollectionReference<DeviceCalibration> | null {
  return deviceId
    ? collection(
        getFirestore(firebaseApp),
        DEVICES_CALIBRATION_COLLECTION_PATH.replace("{uid}", uid).replace("{deviceId}", deviceId),
      ).withConverter(deviceCalibrationConverter)
    : null;
}

export function getStripeCheckoutSessionCollection(uid: string): CollectionReference | null {
  return uid
    ? collection(
        getFirestore(firebaseApp),
        STRIPE_CHECKOUT_SESSION_COLLECTION_PATH.replace("{uid}", uid),
      )
    : null;
}

export function getLeaderboardUserMetadataCollection(): CollectionReference<LeaderboardUserMetadata> {
  return collection(getFirestore(firebaseApp), LEADERBOARD_USER_METADATA_COLLECTION_PATH);
}

export function getLeaderboardStoreMetadataCollection(): CollectionReference<LeaderboardStoreMetadata> {
  return collection(getFirestore(firebaseApp), LEADERBOARD_STORE_METADATA_COLLECTION_PATH);
}

export function getLeaderboardWeeklyCollection(): CollectionReference<LeaderboardEntryWithId> {
  return collection(getFirestore(firebaseApp), LEADERBOARD_WEEKLY_COLLECTION_PATH).withConverter(
    leaderboardConverter,
  );
}

export function getLiveEventsCollection(): CollectionReference<LiveEvent> {
  return collection(getFirestore(firebaseApp), LIVE_EVENTS_COLLECTION_PATH).withConverter(
    liveEventConverter,
  );
}
