const FEATURE_FLAGS = {
  feature_collection: "beta",
  metric_enabled: "on",
  live_event: "off",
  "kiosk-unity": "on",
  lab: "on",
  "unity-simulator": "on",
};

export type FeatureFlag = keyof typeof FEATURE_FLAGS;

export type FeatureFlags = {
  [key in FeatureFlag]: string;
};

export default FEATURE_FLAGS;
