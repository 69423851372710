import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

type RackIconProps = SvgIconProps;

export default function RackIcon(props: RackIconProps) {
  const { color = "black", ...otherProps } = props; // Default color is black

  return (
    <SvgIcon htmlColor={color} viewBox="0 0 500 500" {...otherProps}>
      <path
        d="M56 26C56 21.5817 59.5817 18 64 18H436C440.418 18 444 21.5817 444 26V93H56V26Z"
        fill={color}
      />
      <rect x="56" y="126" width="388" height="75" fill={color} />
      <rect x="56" y="342" width="388" height="75" fill={color} />
      <rect x="56" y="234" width="388" height="75" fill={color} />
      <path
        d="M444 464.5V415.5L410 411V464.5C410 470.167 413.4 481.5 427 481.5C440.6 481.5 444 470.167 444 464.5Z"
        fill={color}
      />
      <path
        d="M90 464.5V415.5L56 411V464.5C56 470.167 59.4 481.5 73 481.5C86.6 481.5 90 470.167 90 464.5Z"
        fill={color}
      />
    </SvgIcon>
  );
}

RackIcon.muiName = "RackIcon";
