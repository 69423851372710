import { FirestoreDataConverter, QueryDocumentSnapshot, SnapshotOptions } from "firebase/firestore";
import { LeaderboardStoreMetadata } from "../../model/leaderboard";

export interface StoreMetadata {
  id: string;
  name?: string;
  website?: string;
  logo?: string;
  phone?: string;
  email?: string;
  availability?: "full" | "limited" | "unavailable";
  affiliateUrl?: string;
  address?: {
    isValid?: boolean;
    addressLines?: string[];
    formattedAddress?: string;
    state?: string;
    city?: string;
    country?: string;
    postalCode?: string;
  };
  geo?: {
    latitude?: number;
    longitude?: number;
  };
  isPremium?: boolean;
  hideMapLocation?: boolean;
}
export const leaderboardStoreMetadataConverter: FirestoreDataConverter<
  StoreMetadata,
  LeaderboardStoreMetadata
> = {
  fromFirestore: (
    snapshot: QueryDocumentSnapshot<LeaderboardStoreMetadata>,
    options: SnapshotOptions,
  ): StoreMetadata => {
    const data = snapshot.data(options);

    const storeMetadata: StoreMetadata = {
      id: snapshot.id,
      name: data.storeName,
      website: data.siteUrl,
      logo: data.logoUrl,
      phone: data.phone,
      email: data.email,
      availability: data.availability,
      affiliateUrl: data.affiliateUrl,
      address: {
        isValid: data.addressValidity !== null,
        formattedAddress: data.addressValidity?.result?.address?.formattedAddress,
        addressLines: data.addressValidity?.result?.address?.postalAddress?.addressLines,
        state: data.addressValidity?.result?.address?.postalAddress?.administrativeArea,
        city: data.addressValidity?.result?.address?.postalAddress?.locality,
        country: data.addressValidity?.result?.address?.postalAddress?.regionCode,
        postalCode: data.addressValidity?.result?.address?.postalAddress?.postalCode,
      },
      geo: data.addressValidity?.result?.geocode?.location,
      isPremium: data?.premiumRole === "store_features",
      hideMapLocation: data?.hideMapLocation,
    };

    return storeMetadata;
  },
  toFirestore: (modelObject: StoreMetadata): LeaderboardStoreMetadata => {
    const toDB = {
      storeName: modelObject.name,
      siteUrl: modelObject.website,
      logoUrl: modelObject.logo,
      phone: modelObject.phone,
      email: modelObject.email,
      availability: modelObject.availability,
      affiliateUrl: modelObject.affiliateUrl,
      premiumRole: modelObject.isPremium ? "store_features" : undefined,
      hideMapLocation: modelObject.hideMapLocation,
      address: modelObject.address?.addressLines?.length
        ? {
            addressLines: modelObject.address?.addressLines,
            administrativeArea: modelObject.address?.state,
            locality: modelObject.address?.city,
            regionCode: modelObject.address?.country,
            postalCode: modelObject.address?.postalCode,
          }
        : undefined,
    };

    return toDB;
  },
};
