import { initializeApp } from "firebase/app";
import { initializeAppCheck, ReCaptchaEnterpriseProvider } from "firebase/app-check";
import { getPerformance } from "firebase/performance";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getAnalytics, logEvent, setUserId } from "firebase/analytics";
import { getFirestore, initializeFirestore } from "firebase/firestore";

export const firebaseConfig = {
  apiKey: "AIzaSyDjb6ivunzWpfM8anij22xdbuY6a4mHdtE",
  authDomain: "techdisc.com",
  projectId: "throw-log",
  storageBucket: "throw-log.appspot.com",
  messagingSenderId: "91797657241",
  appId: "1:91797657241:web:c79308fd70321d4e2a8a08",
  measurementId: "G-4X3PZ09771",
};

export const firebaseApp = initializeApp(firebaseConfig);

declare global {
  interface Window {
    FIREBASE_APPCHECK_DEBUG_TOKEN?: boolean | string;
  }
}

export function setupFirebase() {
  if (window.location.hostname === "localhost") {
    self.FIREBASE_APPCHECK_DEBUG_TOKEN = true;
  }
  initializeAppCheck(firebaseApp, {
    // provider: new ReCaptchaV3Provider("6LdkmoEbAAAAAJHiq60lO5ZJPCYkjF6aN6Y9bgsK"),
    provider: new ReCaptchaEnterpriseProvider("6LcDnBEoAAAAAHiFPwHOWHVVzMVEKcGKWZH38Huf"),
    isTokenAutoRefreshEnabled: true,
  });
  const firebaseAuth = getAuth(firebaseApp);
  const analytics = getAnalytics(firebaseApp);
  onAuthStateChanged(firebaseAuth, (user) => {
    if (user) {
      setUserId(analytics, user.uid);
    }
  });

  initializeFirestore(firebaseApp, { ignoreUndefinedProperties: true });

  const perf = getPerformance(firebaseApp);

  window.addEventListener("error", function (evt) {
    logEvent(getAnalytics(firebaseApp), "browser_uncaught_error", {
      error: JSON.stringify(evt),
      client: "web",
    });
  });

  window.addEventListener("unhandledrejection", (event) => {
    logEvent(getAnalytics(firebaseApp), "browser_ignored_error", {
      error: event.reason,
      client: "web",
    });
  });
}
