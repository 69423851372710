import { getAnalytics, logEvent } from "firebase/analytics";
import { getBytes } from "firebase/storage";
import { Dispatch, SetStateAction } from "react";
import * as THREE from "three";
import { getStorageRef } from "./dashboard/dashboardUtils";
import { firebaseApp } from "./firebaseConfig";
import { Poses } from "./model/poses";
import { getUserId } from "./summaryUtils";

export interface TrackPoint {
  time: number;
  position: THREE.Vector3;
  q: THREE.Quaternion;
}

function parseTrackPoint(view: DataView): TrackPoint {
  const t = view.getFloat32(0);
  const x = view.getFloat32(4);
  const y = view.getFloat32(8);
  const z = view.getFloat32(12);

  const qw = view.getFloat32(16);
  const qx = view.getFloat32(20);
  const qy = view.getFloat32(24);
  const qz = view.getFloat32(28);

  const q = new THREE.Quaternion(qx, qy, qz, qw);
  // q = q.multiply(new THREE.Quaternion(1, 0, 0, 0));
  return {
    time: t,
    position: new THREE.Vector3(x, y, z),
    q: q,
  };
}

const trackPointSerializeSize = 32;

function* iterableFromArray(data: Uint8Array) {
  let index = 0;
  while (data.length >= index + trackPointSerializeSize) {
    const view = new DataView(data.buffer, index, trackPointSerializeSize);
    yield parseTrackPoint(view);
    index += trackPointSerializeSize;
  }
  return index;
}

export async function* asyncIterableFromStream<T>(
  stream: ReadableStream<Uint8Array>,
): AsyncIterable<TrackPoint> {
  function concat(a: Uint8Array, b: Uint8Array): Uint8Array {
    const c = new Uint8Array(a.length + b.length);
    c.set(a, 0);
    c.set(b, a.length);
    return c;
  }

  let buffer: Uint8Array = new Uint8Array();

  const reader = stream.getReader();
  try {
    while (true) {
      const { done, value } = await reader.read();
      if (done) {
        if (buffer.length > 0) {
          throw "extra data";
        }
        return;
      }
      // @ts-ignore
      const data = concat(buffer, value);
      const consumed = yield* iterableFromArray(data);
      buffer = data.subarray(consumed);
    }
  } finally {
    reader.releaseLock();
  }
}

export async function downloadTrackEffect(
  id: string,
  setTrack: (track: TrackPoint[]) => void,
  estimatedFeet?: number | null,
  userId?: string,
) {
  if (!estimatedFeet) {
    return;
  }
  let track = await downloadTrack(id, userId);
  let tries = 0;
  while (!track?.length && tries++ < 4) {
    await new Promise((resolve) => setTimeout(resolve, 200));
    track = await downloadTrack(id, userId);
  }
  if (track?.length > 0) {
    setTrack(track);
    return track;
  } else {
    console.error("failed to download track: " + id);
    logEvent(getAnalytics(firebaseApp), "track_download_error", {
      error: "failed to download repeatedly, check single_track_download_failure",
      client: "web",
      type: "track_failed_after_retries",
    });
  }
  return;
}

export async function downloadTrack(throwId: string, userId?: string): Promise<TrackPoint[]> {
  try {
    const uid = userId ?? getUserId();
    const throwRef = getStorageRef("/throw-track/" + uid + "/" + throwId + ".throw");
    const bytes = await getBytes(throwRef);

    // const url = await getDownloadURL(throwRef);
    // const response = await fetchWithLogging(url);
    const allItems: TrackPoint[] = [];
    for (const item of iterableFromArray(new Uint8Array(bytes))) {
      allItems.push(item);
    }
    return allItems;
  } catch (e) {
    logEvent(getAnalytics(firebaseApp), "track_download_error", {
      error: e,
      client: "web",
      type: "single_track_download_failure",
    });
    throw new Error("failed to download track: " + throwId);
  }
}

export async function downloadPoses(throwId: string) {
  const uid = getUserId();
  const throwRef = getStorageRef("/throw-poses/" + uid + "/" + throwId + ".json");
  const bytes = await getBytes(throwRef);

  // const url = await getDownloadURL(throwRef);
  // const response = await fetchWithLogging(url);
  // const result: Poses = await response.json();

  const result: Poses = JSON.parse(new TextDecoder().decode(bytes));

  return result;
}

export function rotateTrackAroundGravity(track: TrackPoint[], degrees: number): TrackPoint[] {
  const q = new THREE.Quaternion();
  q.setFromAxisAngle(new THREE.Vector3(0, 0, 1), THREE.MathUtils.degToRad(degrees));
  return track.map((point) => {
    const newQ = q.clone().multiply(point.q);
    // const newQ = point.q.clone().multiply(q);
    const newP = point.position.clone().applyQuaternion(q);
    return {
      ...point,
      position: newP,
      q: newQ,
    };
  });
}
