import * as React from "react";
import Typography from "@mui/material/Typography";
import Slider from "@mui/material/Slider";
import { Mark } from "@material-ui/core";
import { FlightPathParams } from "./Simulate3D";
import { mphToMps, radPerSecToRpm } from "../summaryUtils";
import Grid from "@mui/material/Grid";
import { useEffect, useRef, useState } from "react";
import { DiscStats } from "../discStats";
import { FlightNumbers } from "../model/throwSummary";

export interface SliderProps {
  title: string;
  values: Mark[];
  value: number;
  min?: number;
  max?: number;
  track?: "normal" | false | "inverted";
  handleChange: (event: React.SyntheticEvent | Event, value: number) => void;
  step?: null | number;
  isFrozen?: boolean;
}

export function getRunupProps(
  flight: FlightPathParams,
  setFlightState: (
    value: ((prevState: FlightPathParams) => FlightPathParams) | FlightPathParams,
  ) => void,
): SliderProps {
  return {
    title: "Add Runup (mph)",
    step: 1,
    values: [
      { value: 0, label: "0" },
      { value: 10, label: "10" },
      { value: 20, label: "20" },
    ],
    value: flight.runup ? flight.runup / mphToMps : 0,
    handleChange: (e, value) =>
      setFlightState((prevState) => {
        return { ...prevState, runup: mphToMps * value };
      }),
  };
}

export function getDensityProps(
  flight: FlightPathParams,
  setFlightState: (
    value: ((prevState: FlightPathParams) => FlightPathParams) | FlightPathParams,
  ) => void,
): SliderProps {
  return {
    title: "Air Density (kg/m^3)",
    values: [
      { value: 1.3413, label: "cold" },
      { value: 1.225, label: "0 ft" },
      { value: 1.05561674, label: "5k ft" },
      { value: 0.905043181, label: "10k ft" },
    ],
    value: flight.uphill_degrees,
    handleChange: (e, value) =>
      setFlightState((prevState) => {
        return { ...prevState, uphill_degrees: value };
      }),
  };
}

export function getUphillProps(
  flight: FlightPathParams,
  setFlightState: (
    value: ((prevState: FlightPathParams) => FlightPathParams) | FlightPathParams,
  ) => void,
): SliderProps {
  return {
    title: "Launch Angle (degrees)",
    step: 1,
    values: [
      { value: -10, label: "-10" },
      { value: -5, label: "-5" },
      { value: 0, label: "0" },
      { value: 5, label: "5" },
      { value: 10, label: "10" },
      { value: 15, label: "15" },
      { value: 20, label: "20" },
      { value: 30, label: "30" },
      { value: 40, label: "40" },
      { value: 70, label: "70" },
    ],
    value: flight.uphill_degrees,
    handleChange: (e, value) =>
      setFlightState((prevState) => {
        return { ...prevState, uphill_degrees: value };
      }),
  };
}

export function getHyzerProps(
  flight: FlightPathParams,
  setFlightState: (
    value: ((prevState: FlightPathParams) => FlightPathParams) | FlightPathParams,
  ) => void,
): SliderProps {
  const values = [...Array(180 / 5 + 1)].map((_, i) => -90 + i * 5);
  const marks = values.map((v, i) => {
    return { value: v, label: v % 15 == 0 ? v : "" };
  });
  return {
    title: "Hyzer Angle (degrees)",
    step: 1,
    values: [
      { value: -90, label: "-90" },
      { value: -45, label: "roller" },
      { value: -20, label: "anhyzer" },
      { value: 0, label: "" },
      { value: 20, label: "hyzer" },
      { value: 45, label: "spike" },
      { value: 90, label: "90" },
    ],
    value: DiscStats.isUpsideDown(flight.hyzer_degrees)
      ? DiscStats.flipUpsideDown(flight.hyzer_degrees)
      : flight.hyzer_degrees,
    handleChange: (e, value) =>
      setFlightState((prevState) => {
        let newHyzer = value;
        if (DiscStats.isUpsideDown(prevState.hyzer_degrees)) {
          newHyzer = DiscStats.flipUpsideDown(newHyzer);
        }
        return { ...prevState, hyzer_degrees: newHyzer };
      }),
  };
}

export function getNoseUpProps(
  flight: FlightPathParams,
  setFlightState: (
    value: ((prevState: FlightPathParams) => FlightPathParams) | FlightPathParams,
  ) => void,
): SliderProps {
  return {
    title: "Nose Angle (degrees)",
    step: 1,
    values: [
      { value: -20, label: "-20" },
      { value: -10, label: "-10" },
      { value: -5, label: "-5" },
      { value: 0, label: "0" },
      { value: 5, label: "5" },
      { value: 10, label: "10" },
      { value: 15, label: "15" },
      { value: 20, label: "20" },
    ],
    value: flight.nose_up_degrees,
    handleChange: (e, value) =>
      setFlightState((prevState) => {
        return { ...prevState, nose_up_degrees: value };
      }),
  };
}

export function getSpinProps(
  flight: FlightPathParams,
  setFlightState: (
    value: ((prevState: FlightPathParams) => FlightPathParams) | FlightPathParams,
  ) => void,
): SliderProps {
  return {
    title: "Spin (RPM)",
    track: "normal",
    step: 100,
    values: [
      { value: 400, label: "400" },
      { value: 600, label: "600" },
      { value: 1000, label: "1000" },
      { value: 1200, label: "1200" },
      { value: 1600, label: "1600" },
    ],
    value: Math.abs(flight.spin * radPerSecToRpm),
    handleChange: (e, value) =>
      setFlightState((prevState) => {
        return {
          ...prevState,
          spin: (Math.sign(prevState.spin) * value) / radPerSecToRpm,
        };
      }),
  };
}

export function getWindSpeedProps(
  flight: FlightPathParams,
  setFlightState: (
    value: ((prevState: FlightPathParams) => FlightPathParams) | FlightPathParams,
  ) => void,
): SliderProps {
  return {
    title: "Wind Speed (MPH)",
    track: "normal",
    step: 1,
    values: [
      { value: 0, label: "0" },
      { value: 10, label: "10" },
      { value: 20, label: "20" },
      { value: 30, label: "30" },
      { value: 40, label: "40" },
      { value: 50, label: "50" },
    ],
    value: (flight.wind_speed ?? 0) / mphToMps,
    handleChange: (e, value) =>
      setFlightState((prevState) => {
        return {
          ...prevState,
          wind_speed: value * mphToMps,
        };
      }),
  };
}

export function getMphProps(
  flight: FlightPathParams,
  setFlightState: (
    value: ((prevState: FlightPathParams) => FlightPathParams) | FlightPathParams,
  ) => void,
): SliderProps {
  return {
    title: "Speed (MPH)",
    track: "normal",
    step: 1,
    values: [
      { value: 20, label: "20" },
      { value: 40, label: "40" },
      { value: 60, label: "60" },
      { value: 80, label: "80" },
    ],
    value: flight.v / mphToMps,
    handleChange: (e, value) =>
      setFlightState((prevState) => {
        return { ...prevState, v: mphToMps * value };
      }),
  };
}

export default function SimSlider(props: SliderProps) {
  const sliderEl = useRef(null);
  const [value, setValue] = useState(props.value);
  useEffect(() => {
    setValue(props.value);
  }, [props.value]);
  const handleChange = (event: Event, newValue: number | number[]) => {
    if (!props.isFrozen) {
      setValue(newValue as number);
    }
  };

  let extra = {};
  if (props.isFrozen) {
    extra = { disabled: true };
  }
  return (
    <Grid sx={{ mt: 2, mb: 2 }}>
      <Typography variant="h6" gutterBottom>
        {props.title}
      </Typography>
      <Slider
        {...extra}
        ref={sliderEl}
        key={`slider-${props.title}`}
        aria-label={props.title}
        getAriaValueText={(n, i) => value.toFixed(2)}
        // valueLabelFormat={(v, i) => Math.round(v * v)}
        value={value}
        onChange={handleChange}
        onChangeCommitted={(a, b) => {
          if (!props.isFrozen) {
            props.handleChange(a, b);
          }
        }}
        marks={props.values}
        track={props.track || false}
        //scale={e => Math.sqrt(e)}
        step={props.step || null}
        min={props.min || props.values[0].value}
        max={props.max || props.values[props.values.length - 1].value}
        valueLabelDisplay={props.isFrozen ? "on" : "auto"}
        valueLabelFormat={(num) => value.toFixed(2)}
      />
    </Grid>
  );
}
