//import firebase from "firebase";
import { getAuth } from "firebase/auth";
import { firebaseApp } from "./firebaseConfig";

export function getQueryMap(): Map<string, string> {
  const queryDict = new Map<string, string>();
  location.search
    .substr(1)
    .split("&")
    .forEach(function (item) {
      queryDict.set(item.split("=")[0], item.split("=")[1]);
    });
  return queryDict;
}

export function setQueryString(object: any): void {
  const queryString = new URLSearchParams(object).toString();
  window.history.replaceState(null, "", "simulate?" + queryString);
}

export function downloadBlob(fileName: string, buf: ArrayBuffer) {
  const a = document.createElement("a");
  const file = new Blob([buf], { type: "application/octet-stream" });
  const url = URL.createObjectURL(file);
  a.href = url;
  a.download = fileName;
  document.body.appendChild(a);
  a.click();
  setTimeout(function () {
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  }, 0);
}

export async function postUrl(url: string, data: any): Promise<Response> {
  const user = getAuth(firebaseApp).currentUser;
  if (!user) {
    throw "no user";
  }
  const token = await user.getIdToken();
  return fetch(url, {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  });
}

export function downloadUrl(url: string) {
  const a = document.createElement("a");
  a.href = url;
  a.download = "";
  document.body.appendChild(a);
  a.click();
  setTimeout(function () {
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  }, 0);
}

export function getUidQueryParam(): string {
  const queryMap = getQueryMap();
  let uidQuery = "";
  if (window.location.pathname.startsWith("/live")) {
    uidQuery = "live&";
  } else if (queryMap.has("uid")) {
    const uid = queryMap.get("uid");
    uidQuery = "uid=" + uid + "&";
  }
  return uidQuery;
}

export function getCountQueryParam(): number {
  const queryMap = getQueryMap();
  if (queryMap.has("count")) {
    return Number(queryMap.get("count"));
  }
  return 5;
}
export function getThrowIdQueryParam(): string | undefined {
  const queryMap = getQueryMap();
  if (queryMap.has("throwId")) {
    return queryMap.get("throwId");
  }
  return queryMap.get("id");
}

export function getDeviceIdQueryParam(): string | undefined {
  const queryMap = getQueryMap();
  if (queryMap.has("deviceId")) {
    return queryMap.get("deviceId");
  }
  return undefined;
}

export function getIdsQueryParam(): string[] | undefined {
  const queryMap = getQueryMap();
  if (queryMap.has("ids")) {
    const idString = queryMap.get("ids");
    return idString?.split(",");
  }
  return undefined;
}

export function getDeviceUidQueryParam(): string | undefined {
  const queryMap = getQueryMap();
  if (queryMap.has("deviceUid")) {
    return queryMap.get("deviceUid");
  }
  return undefined;
}
