import {
  DocumentData,
  FirestoreDataConverter,
  QueryDocumentSnapshot,
  SnapshotOptions,
} from "firebase/firestore";
import { DeviceCalibration } from "../../model/device";

export const deviceCalibrationConverter: FirestoreDataConverter<DeviceCalibration> = {
  fromFirestore: (
    snapshot: QueryDocumentSnapshot<DeviceCalibration>,
    options: SnapshotOptions,
  ): DeviceCalibration => {
    const data = snapshot.data(options);

    return { ...data } as DeviceCalibration; // Adjust this mapping as necessary
  },
  toFirestore: (modelObject: DeviceCalibration): DocumentData => {
    return { ...modelObject };
  },
};
