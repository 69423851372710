import type { LiveEvent } from "@/firebase/converters/events";
import { User } from "firebase/auth";
import { useParams, useRouteLoaderData } from "react-router-dom";
import { useGlobal } from "../components/providers/GlobalProvider";
import { LIVE_USER_ID } from "../dashboard/liveEvents";
import UserSettings from "../model/UserSettings";

export const useUser = (): [
  {
    userLoading: boolean;
    user?: User | null;
    userSettings?: UserSettings;
    userId: string;
    externalUserId?: string;
    publicUserId: string;
    trueUserId?: string;
  },
  boolean,
] => {
  const { user, userSettings, userLoading } = useGlobal();
  const { activeEvent } = (useRouteLoaderData("root") ?? {}) as { activeEvent: LiveEvent };
  const { userId: externalUserId } = useParams();
  const publicUserId = activeEvent ? LIVE_USER_ID : "unknown";
  const trueUserId = user?.uid;
  const userId = externalUserId ?? trueUserId ?? publicUserId;

  return [
    {
      user,
      userSettings,
      userLoading,
      userId,
      externalUserId,
      publicUserId,
      trueUserId,
    },
    userLoading,
  ];
};
