// not exactly sure where this file should live, more of a service / module

import { FilterType } from "../analysis/ThrowTable";
import { passesAllFilters } from "../analysis/shared/utility";
import { ClientCoreMetrics, CoreMetrics } from "../firebase/converters/analysisSet";
import { CoreStats } from "../model/CoreStats";
import { ThrowSummary } from "../model/throwSummary";

const SUGGESTED_TAGS: string[] = [
  "Favorite",
  "Power Grip",
  "Fan Grip",
  "Standstill",
  "X-Step",
  "50% Power",
  "80% Power",
  "Full Send",
  "A",
  "B",
  // { id: "Speed Winner", text: "Speed Winner" },
  // { id: "DEBUG", text: "DEBUG" },
];

// internal used funcs
function filterByTags<TData extends CoreStats = CoreMetrics>(tags: string[], docs: TData[]) {
  if (!tags || tags.length === 0) {
    return docs;
  }
  return docs.filter((doc: TData) => {
    if (doc.tags && doc.tags.length > 0) {
      return tags.every((tag) => doc.tags?.includes(tag));
      // return doc.tags.some((tag) => tags.includes(tag));
    }
    return false;
  });
}

// TODO brett not used yet but want to work this in here and have update properly per TableTags comment
function getDocTags(
  docs: ThrowSummary[], // both types are working, but thinking could do this better
  filters?: FilterType[],
  tags?: string[],
): Map<string, number> {
  const map = new Map<string, number>();
  docs.forEach((doc) => {
    if (!doc.tags?.length) {
      return;
    }

    const passes =
      filters &&
      filters.length &&
      passesAllFilters(filters, doc) &&
      filterByTags(tags || [], [doc]).length > 0;

    doc.tags?.forEach((tag: string) => {
      if (!map.has(tag)) {
        map.set(tag, 0);
      }
      if (passes) {
        map.set(tag, (map.get(tag) ?? 0) + 1);
      }
    });
  });
  // sort map by value then by key
  const sorted = new Map(
    [...map.entries()].sort((a, b) => b[1] - a[1] || a[0].localeCompare(b[0])),
  );

  // if (getTrueUserId() !== getUserId() && !POWER_USER_IDS.has(getTrueUserId())) {
  sorted.delete("DEBUG");
  sorted.delete("Speed Winner");
  // }
  return sorted;
}

// internal localStorage manager
const store = {
  getSuggestedTags: (uid: string): string[] => {
    let suggestedTags = [];
    try {
      suggestedTags = JSON.parse(localStorage.getItem(`${uid}-suggestedTags`) || "[]");
    } catch (e) {
      suggestedTags = [];
    }
    return suggestedTags;
  },
  setSuggestedTags: (uid: string, tags: string[]): void => {
    localStorage.setItem(`${uid}-suggestedTags`, JSON.stringify(tags));
  },
  getLockedTags: (uid: string): string[] => {
    let lockedTags = [];
    try {
      lockedTags = JSON.parse(localStorage.getItem(`${uid}-lockedTags`) || "[]");
    } catch (e) {
      lockedTags = [];
    }
    return lockedTags;
  },
  setLockedTags: (uid: string, tags: string[]): void => {
    localStorage.setItem(`${uid}-lockedTags`, JSON.stringify(tags));
  },
};

export default {
  isLoaded: (uid: string): boolean => {
    if (!uid || uid == "unknown") {
      return true; // skips any additional queries that are intended for logged-in user
    }
    return !!store.getSuggestedTags(uid)?.length;
  },
  initSuggestedTags: (uid: string, docs: ThrowSummary[]): void => {
    if (!uid || uid == "unknown") {
      return;
    }
    const sorted = getDocTags(docs);
    // TODO only keeping this for a bit to clear out any old use of storage
    localStorage.removeItem("lockedTags");
    localStorage.removeItem("suggestedTags");
    store.setSuggestedTags(uid, [...new Set([...Array.from(sorted.keys()), ...SUGGESTED_TAGS])]);
  },
  updateSuggestedTags: (uid: string, docs: ThrowSummary[]): void => {
    if (!uid || uid == "unknown") {
      return;
    }
    const suggestedTags = store.getSuggestedTags(uid);
    // looping backwards to get oldest first
    for (let index = docs.length - 1; index >= 0; index--) {
      docs[index]?.tags?.forEach((tag: string) => {
        suggestedTags.unshift(tag);
      });
    }
    store.setSuggestedTags(uid, [...new Set(suggestedTags)]);
  },
  getSuggestedTags: (uid: string) => {
    if (!uid || uid == "unknown") {
      return SUGGESTED_TAGS;
    }
    let suggestedTags = store.getSuggestedTags(uid);
    if (!suggestedTags.length) {
      suggestedTags = SUGGESTED_TAGS; // only for empty, cause init will append and then deletions will be handled
    }
    return suggestedTags;
  },
  addSuggestedTag: (uid: string, tag: string): void => {
    if (!uid || uid == "unknown") {
      return;
    }
    const suggestedTags = store.getSuggestedTags(uid);
    store.setSuggestedTags(uid, [...new Set([tag, ...suggestedTags])]);
  },
  deleteSuggestedTag: (uid: string, tag: string, filteredSuggested?: string[]): string[] => {
    if (!uid || uid == "unknown") {
      return SUGGESTED_TAGS;
    }
    const suggestedTags = store.getSuggestedTags(uid).filter((el: string) => el !== tag);
    store.setSuggestedTags(uid, suggestedTags);
    if (filteredSuggested) {
      return filteredSuggested.filter((el: string) => el !== tag);
    } else {
      return suggestedTags;
    }
  },
  getDocTags: getDocTags,
  filterByTags: filterByTags,
  getLockedTags: (uid: string): string[] => {
    if (!uid || uid == "unknown") {
      return [];
    }
    return store.getLockedTags(uid);
  },
  setLockedTags: (uid: string, lockedTags: string[]): void => {
    if (!uid || uid == "unknown") {
      return;
    }
    store.setLockedTags(uid, lockedTags);
  },
};
