import {
  Avatar,
  Box,
  Button,
  Checkbox,
  Divider,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { createCheckoutSession } from "../../stripe";
import { FieldValues, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { AnimatePresence, motion } from "framer-motion";
import { saveLeaderboardStoreMetadata } from "../../summaryUtils";
import { LeaderboardStoreMetadata } from "../../model/leaderboard";
import Title from "../../dashboard/Title";
import { useGlobal } from "../providers/GlobalProvider";
import React, { useRef, useState } from "react";
import useNotify from "../../hooks/useNotify";
import { FormEvent, useEffect } from "react";
import JSONView from "react-json-view";
import { getFunctions, httpsCallable } from "firebase/functions";
import { firebaseApp } from "../../firebaseConfig";
import { useUserMetadata } from "../../hooks/useUserMetadata";
import { useStoreMetadata } from "../../hooks/useStoreMetadata";

export enum AuthClaim {
  admin = "admin", // admin users can give these roles to other users and read and write everything
  manager = "manager", // manager users can read everything and write to non-manager/admins
  debug = "debug", // read everything
  debugThrows = "debugThrows", // has access to throw debugging tools
  store = "store", // verified store users can enter their address to get on the map
  beta = "beta", // beta users can test out new features that are almost ready
  devices = "devices", // This is for users that can interact with the devices page
  dev = "dev", // dev users can see features that are in progress but not ready for beta plus debug tools/views
  earlyAccess = "earlyAccess", // early access to new features, agreed to additional feedback requests
}

interface AuthClaimRequest {
  email?: string;
  uid?: string;
  setClaims?: AuthClaim[];
}

export interface UserRecord {
  uid: string;
  email: string;
  emailVerified: boolean;
  displayName?: string;
  photoURL?: string;
  disabled: boolean;
  customClaims?: {
    [key: string]: any;
  };
}

interface AuthClaimResult {
  user: UserRecord;
  newClaims?: { [key: string]: any };
}

export async function getAuthClaims(email?: string, uid?: string) {
  const request: AuthClaimRequest = { email, uid };
  const result = await httpsCallable(getFunctions(firebaseApp), "updateAuthClaims")(request);
  return result.data as AuthClaimResult;
}

async function storeAuthClaims(email: string, setClaims: AuthClaim[]) {
  const request: AuthClaimRequest = { email, setClaims };
  const result = await httpsCallable(getFunctions(firebaseApp), "updateAuthClaims")(request);
  return result.data as AuthClaimResult;
}

function getDescription(role: AuthClaim) {
  switch (role) {
    case AuthClaim.admin:
      return "Can read and write everything and set roles for users";
    case AuthClaim.manager:
      return "Can grant/revoke roles for users but not admin or manager roles";
    case AuthClaim.debug:
      return "Can read everything";
    case AuthClaim.debugThrows:
      return "Can access throw debugging tools";
    case AuthClaim.store:
      return "Can enter their address to get on the map";
    case AuthClaim.beta:
      return "Testers for stuff that is almost ready (unity)";
    case AuthClaim.devices:
      return "Build and Ship TD devices. Can read and edit all device info";
    case AuthClaim.dev:
      return "In progress and debugging features";
    case AuthClaim.earlyAccess:
      return "Early access to new features, agreed to additional feedback requests";
  }
}

function UserAndClaims(props: { user?: UserRecord; setUser: (u: UserRecord) => void }) {
  const notify = useNotify();

  const claims = Object.keys(props.user?.customClaims ?? {}).filter(
    (claim) => claim in AuthClaim,
  ) as AuthClaim[];
  const [selectedRoles, setSelectedRoles] = useState<AuthClaim[]>(claims);
  const [lastEmail, setLastEmail] = useState(props.user?.email);

  useEffect(() => {
    if (props.user?.email !== lastEmail) {
      setLastEmail(props.user?.email);
      setSelectedRoles(claims);
    }
  }, [props.user?.email, claims, lastEmail]);

  const toggleRole = (role: AuthClaim) => {
    setSelectedRoles((prev) =>
      prev.includes(role) ? prev.filter((r) => r !== role) : [...prev, role],
    );
  };

  async function handleSubmit(event: FormEvent) {
    event.preventDefault();
    if (!props.user) return;
    await storeAuthClaims(props.user.email, selectedRoles);
    notify("success", "Saved successfully!");
  }

  return (
    <Stack component="form" gap={2} sx={{ height: "100%" }} onSubmit={handleSubmit}>
      <Title variant="secondary">Role Management</Title>
      <Stack gap={1}>
        {Object.values(AuthClaim).map((role) => (
          <Stack direction="row" key={role} alignItems={"flex-start"}>
            <Checkbox
              id={role}
              checked={selectedRoles.includes(role)}
              onChange={() => toggleRole(role)}
              disabled={!props.user}
            />
            <Box component="label" sx={{ my: 1 }} htmlFor={role}>
              <Typography
                variant="body1"
                sx={{
                  fontWeight: 500,
                  color: !props.user ? (theme) => theme.palette.grey[500] : "inherit",
                }}
              >
                {role}
              </Typography>
              <Typography
                variant="caption"
                sx={{ color: !props.user ? (theme) => theme.palette.grey[500] : "inherit" }}
              >
                {getDescription(role)}
              </Typography>
            </Box>
          </Stack>
        ))}
      </Stack>
      <Stack direction={"row"} justifyContent={"flex-end"} gap={2}>
        <Button
          onClick={(e) => {
            if (props.user?.uid) {
              createCheckoutSession(props.user.uid);
            }
          }}
          variant="outlined"
          disabled={!props.user}
        >
          Create Stripe Account
        </Button>
        <Button type="submit" variant="primary" disabled={!props.user}>
          Update Roles
        </Button>
      </Stack>
    </Stack>
  );
}

export const PermissionsManager = () => {
  const { user: myUser, isAdmin, userId } = useGlobal();
  const [myUserMetadata] = useUserMetadata(userId);
  const [lookupUser, setLookupUser] = useState<UserRecord>();
  const [storeMetadata] = useStoreMetadata(lookupUser?.uid);

  const getUser = async (payload: FieldValues) => {
    const { email, userId } = payload;
    if (!email && !userId) return;
    const result = await getAuthClaims(email, userId);
    setLookupUser(result.user);
  };

  const lookupSchema = React.useMemo(() => {
    return yup.object({
      email: yup.string().optional(),
      userId: yup.string().optional(),
    });
  }, []);

  const submitRef = useRef<HTMLButtonElement>(null);

  const { handleSubmit, register, formState } = useForm({
    resolver: yupResolver(lookupSchema),
  });

  return (
    <Stack py={4} px={8} gap={2} direction="row">
      <Stack component={Paper} gap={2} sx={{ p: 2, width: "40%" }}>
        <Stack component="form" gap={2} onSubmit={handleSubmit(getUser)}>
          <TextField label="User Email" inputProps={{ ...register("email") }} />
          <TextField label="User ID" inputProps={{ ...register("userId") }} />
          <Stack direction="row" gap={2}>
            <Button
              type="submit"
              variant="primary"
              disabled={!formState.isValid || !formState.isDirty}
              fullWidth
            >
              Lookup
            </Button>
            <Avatar
              src={myUserMetadata?.photo ?? myUser?.photoURL}
              sx={{ "&:hover": { cursor: "pointer" } }}
              onClick={() => getUser({ email: myUser?.email, userId: myUser?.uid })}
            />
          </Stack>
        </Stack>
        <Divider />
        {lookupUser &&
          isAdmin &&
          Object.keys(lookupUser.customClaims ?? {}).includes(AuthClaim.store) &&
          storeMetadata && <EditStoreMetadata user={lookupUser} metadata={storeMetadata} />}
        <UserAndClaims user={lookupUser} setUser={setLookupUser} />
      </Stack>
      <Paper sx={{ p: 2, width: "60%" }}>
        {lookupUser && <JSONView quotesOnKeys={false} indentWidth={2} src={lookupUser} />}
      </Paper>
    </Stack>
  );
};

function EditStoreMetadata({
  metadata,
  user,
}: {
  user?: UserRecord | null;
  metadata: LeaderboardStoreMetadata;
}) {
  const notify = useNotify();

  const editSchema = React.useMemo(() => {
    return yup.object({
      affiliateUrl: yup.string().url().optional(),
    });
  }, []);

  const {
    handleSubmit: handleEditSubmit,
    register: editRegister,
    formState,
    reset,
  } = useForm({
    defaultValues: {
      affiliateUrl: metadata?.affiliateUrl ?? "",
    },
    resolver: yupResolver(editSchema),
  });

  const handleUpdateUser = React.useCallback(
    async (values: FieldValues) => {
      await saveLeaderboardStoreMetadata(user?.uid ?? "", { affiliateUrl: values.affiliateUrl });
      notify("success", "Store information updated.");
    },
    [user, notify],
  );

  useEffect(() => {
    if (metadata) {
      reset({ affiliateUrl: metadata.affiliateUrl });
    }
  }, [metadata, reset]);

  return (
    <AnimatePresence>
      <Stack
        component={motion.form}
        initial={{ opacity: 0, maxHeight: "0px" }}
        animate={{ opacity: 1, maxHeight: "100%" }}
        exit={{ opacity: 0, maxHeight: "0px" }}
        gap={2}
        alignItems="flex-end"
        onSubmit={handleEditSubmit(handleUpdateUser)}
      >
        <Box sx={{ alignSelf: "flex-start" }}>
          <Title variant="secondary">Store Management</Title>
        </Box>
        <TextField
          label="Affiliate URL"
          inputProps={{ ...editRegister("affiliateUrl") }}
          sx={{ width: "100%" }}
          error={formState.errors.affiliateUrl}
          helperText={formState.errors.affiliateUrl?.message}
        />
        <Button
          type="submit"
          variant="primary"
          disabled={!formState.isDirty || !formState.isValid}
          sx={{ width: "fit-content" }}
        >
          Update Store
        </Button>
      </Stack>
    </AnimatePresence>
  );
}

export default EditStoreMetadata;
