import React from "react";
import { SplitFactoryProvider } from "@splitsoftware/splitio-react";
import FEATURE_FLAGS from "../../featureFlags";
import { getAuth } from "firebase/auth";
import SplitIO from "@splitsoftware/splitio/types/splitio";

const user = getAuth().currentUser;

const config: SplitIO.IBrowserSettings = {
  userConsent: "GRANTED",
  core: {
    authorizationKey: window.location.origin.includes("localhost")
      ? "localhost" // local flags, defined below
      : window.location.origin.includes("techdisc.com")
        ? "fmlqj0rcjsb0blc19ojj84memc34clhjc2j9" // prod flags
        : "hrmhq6uia5bbkooepk828nnrlin371fr736", // develop flags
    key: user?.uid ?? "unknown",

    trafficType: user?.uid ? "user" : "anonymous",
  },
  sync: {
    // Remove when using more features -- https://help.split.io/hc/en-us/articles/360020448791-JavaScript-SDK#configuration
    // impressionsMode: "NONE",
  },
  features: FEATURE_FLAGS,
};

export default function SplitProvider({ children }: { children: React.ReactNode }) {
  return <SplitFactoryProvider config={config}>{children}</SplitFactoryProvider>;
}
