import { type Device } from "../model/device";
import { MenuItem, Select, type SelectChangeEvent } from "@mui/material";
import type { DiscPreset } from "../model/discs";

export function DiscPresetSelect(props: {
  device?: Device;
  onChange: (event: SelectChangeEvent) => void;
  discPresets?: DiscPreset[];
}) {
  const { device, onChange, discPresets } = props;

  return (
    <Select
      size="small"
      displayEmpty
      onChange={onChange}
      value={device?.discPresetId}
      defaultValue={device?.discPresetId}
      placeholder="Select a disc"
      labelId="disc-preset-select-label"
      sx={{ width: "196px" }}
    >
      <MenuItem disabled value="" sx={{ color: "grey.500" }}>
        <em>Select a disc</em>
      </MenuItem>
      {discPresets?.map((preset) => (
        <MenuItem key={preset.id} value={preset.id}>
          {preset.name}
        </MenuItem>
      ))}
    </Select>
  );
}
