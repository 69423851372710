import type { UserRole } from "@/types/user";
import {
  type DocumentData,
  type FirestoreDataConverter,
  type QueryDocumentSnapshot,
  type SnapshotOptions,
} from "firebase/firestore";

export const userRolesConverter: FirestoreDataConverter<UserRole> = {
  fromFirestore: (
    snapshot: QueryDocumentSnapshot<UserRole>,
    options: SnapshotOptions,
  ): UserRole => {
    const data = snapshot.data(options);

    return { ...data, id: snapshot.id } as UserRole; // Adjust this mapping as necessary
  },
  toFirestore: (modelObject: UserRole): DocumentData => {
    const { id, ...userRole } = modelObject;
    return { ...userRole };
  },
};
