import { fromFirestore, toFirestore } from "@/firebase/converters/converter";
import type { SimulatorCourse, SimulatorCourseDocument } from "@/types/simulator";
import type { FirestoreDataConverter } from "firebase/firestore";

export const simulatorCourseConverter: FirestoreDataConverter<
  SimulatorCourse,
  SimulatorCourseDocument
> = {
  fromFirestore,
  toFirestore,
};
