import {
  DocumentData,
  FirestoreDataConverter,
  QueryDocumentSnapshot,
  SnapshotOptions,
} from "firebase/firestore";
import { Device } from "../../model/device";

export const deviceConverter: FirestoreDataConverter<Device> = {
  fromFirestore: (snapshot: QueryDocumentSnapshot<Device>, options: SnapshotOptions): Device => {
    const data = snapshot.data(options);

    return { ...data } as Device; // Adjust this mapping as necessary
  },
  toFirestore: (modelObject: Device): DocumentData => {
    return { ...modelObject };
  },
};
