import { useParams } from "react-router-dom";

function useIds() {
  const params = useParams();

  const userId = params.userId;
  const throwId = params.throwId;
  const throwSetId = params.throwSetId;

  return { userId, throwId, throwSetId };
}

export default useIds;
