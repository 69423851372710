import * as THREE from "three";
import { Group, Quaternion, Vector3, Vector3Tuple } from "three";
import React, { CSSProperties, useEffect, useRef, useState } from "react";
import { TrackPoint } from "../trackUtils";
import { animateThrow, FEET_TO_METERS } from "../3dUtils";
import { loadModelEffect } from "./Flight3D";
import { firebaseApp } from "../firebaseConfig";
import { getAnalytics, logEvent } from "firebase/analytics";
import { FlightNumbers } from "../model/throwSummary";

// This service uses Z as up, not down, so everything is flipped
export interface FlightPathParams {
  disc_numbers?: FlightNumbers[];
  flight_numbers?: FlightNumbers;
  // disc_name: string;
  v: number; // velocity (m/s)
  spin: number; // angular velocity Z (rad/s)
  uphill_degrees: number;
  hyzer_degrees: number;
  nose_up_degrees: number;
  wx?: number;
  wy?: number;
  z?: number; // starting height (defualts to 1)
  gamma?: number; // starting angular offset in Z axis
  runup?: number; // runup in m/s
  wind_speed?: number; // wind speed in m/s
  wind_angle?: number; // wind angle in rads, 0 is tail wind, 90 deg is right to left
}

interface FetchResult {
  p: Vector3Tuple[];
  v: Vector3Tuple[];
  t: number[];
  qx: number[];
  qy: number[];
  qz: number[];
  qw: number[];
  gamma: number[];
}

type SimulateProps = {
  setDistance?: (dist: number) => void;
  previousTrack?: TrackPoint[];
  isFlightPathMode?: boolean;
  flightParams: FlightPathParams[];
};

function toPositionList(track: FetchResult): TrackPoint[] {
  // Flight path tool has Z point up instead of the traditional z down
  // This means we have to adjust the points by 180 degrees around X
  const adjust = new THREE.Quaternion().setFromAxisAngle(new THREE.Vector3(1, 0, 0), Math.PI);
  const result: TrackPoint[] = [];
  for (let i = 0; i < track.t.length; i++) {
    let rotation = new THREE.Quaternion(track.qx[i], track.qy[i], track.qz[i], track.qw[i]);
    const qGamma = new THREE.Quaternion().setFromAxisAngle(new Vector3(0, 0, 1), track.gamma[i]);
    rotation.multiply(qGamma);
    rotation = new Quaternion().multiplyQuaternions(rotation, adjust);
    rotation = adjust.clone().multiply(rotation);
    // rotation = new Quaternion().multiplyQuaternions(adjust, rotation);
    const time = track.t[i];
    const xyz = track.p[i];
    const position: Vector3 = new Vector3(xyz[0], xyz[1], xyz[2]);
    position.applyQuaternion(adjust);
    result.push({ position: position, time: time, q: rotation });
  }
  return result;
}

async function fetchFlight(data: FlightPathParams) {
  logEvent(getAnalytics(firebaseApp), "simulate_flight");
  data = { ...data, v: data.v + (data.runup || 0) };
  const response = await fetch("https://flight-api.techdisc.com/api/flight_path", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
  const track: FetchResult = await response.json();
  return toPositionList(track);
}

async function fetchFlights(data: FlightPathParams) {
  logEvent(getAnalytics(firebaseApp), "simulate_flights", {
    count: data.disc_numbers?.length,
  });
  data = { ...data, v: data.v + (data.runup || 0) };
  const response = await fetch("https://flight-api.techdisc.com/api/flight_paths", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
  const tracks: { [key: number]: FetchResult } = await response.json();
  const trackList: TrackPoint[][] = [];
  Object.entries(tracks).forEach(([key, value]) => {
    const index = Number(key);
    trackList[index] = toPositionList(value);
  });

  return trackList;
}

export default function Simulate3D(props: SimulateProps) {
  const [tracks, setTracks] = useState(props.previousTrack ? [props.previousTrack] : []);
  const [discScene, setDiscScene] = useState<Group>();
  const canvasEl = useRef<HTMLCanvasElement>(null);
  const wrapDiv = useRef<HTMLDivElement>(null);
  useEffect(() => {
    let isCancelled = false;
    (async () => {
      const singleFlight: boolean =
        props.flightParams.length === 1 && (props.flightParams[0].disc_numbers?.length ?? 0) <= 1;
      let loadedTracks: TrackPoint[][];
      if (singleFlight) {
        loadedTracks = [await fetchFlight(props.flightParams[0])];
      } else if (props.flightParams.length > 1) {
        loadedTracks = await Promise.all(
          props.flightParams.map((flight) => {
            return fetchFlight(flight);
          }),
        );
      } else {
        loadedTracks = await fetchFlights(props.flightParams[0]);
      }
      if (!isCancelled && singleFlight && loadedTracks.length) {
        let track = loadedTracks[0];
        const distance = track[track.length - 1].position.length() / FEET_TO_METERS;
        props.setDistance?.(distance);

        if (props.previousTrack) {
          const theta = (props.flightParams[0].uphill_degrees * Math.PI) / 180;

          const direction = new THREE.Vector3(Math.cos(theta), 0, Math.sin(theta));
          const fullTrack: TrackPoint[] = [];
          for (const point of props.previousTrack) {
            if (point.time > 0) {
              // Skip the track computed by the backend.
              continue;
            }

            const pos = point.position.clone();
            pos.addScaledVector(direction, point.time * (props.flightParams[0].runup || 0));
            fullTrack.push({ ...point, position: pos });
          }
          for (const point of track) {
            fullTrack.push(point);
          }
          track = fullTrack;
        }

        setTracks([track]);
      } else if (!isCancelled) {
        setTracks(loadedTracks);
      }
    })();
    return () => {
      isCancelled = true;
    };
  }, [props]);
  useEffect(loadModelEffect(setDiscScene), []);
  const canvasStyle = {
    display: "inline-block",
    width: 1,
  };
  if (wrapDiv.current) {
    const mapDimensions = wrapDiv.current.getBoundingClientRect();
    canvasStyle.width = mapDimensions.width;
  }
  useEffect(() => {
    let isCancelled = false;
    if (tracks.length && canvasEl.current && discScene && wrapDiv.current) {
      const width = wrapDiv.current.getBoundingClientRect().width;
      canvasEl.current.width = width;
      canvasEl.current.height = Math.round((width * 9.0) / 16);
      return animateThrow(
        canvasEl.current,
        {
          uphillAngle: props.flightParams[0].uphill_degrees,
          rotPerSec: props.flightParams[0].spin / (2 * Math.PI),
        },
        tracks,
        discScene,
        {
          addGui: !props.isFlightPathMode,
          isFlightPathMode: props.isFlightPathMode,
          flightNumbers: props.flightParams[0].disc_numbers,
        },
      );
    }
    return () => {
      isCancelled = true;
    };
  }, [tracks, discScene, props.flightParams, props.isFlightPathMode]);
  const divStyle: CSSProperties = {
    position: "relative",
  };
  // let uidQuery = getUidQueryParam();
  return (
    <div ref={wrapDiv} style={divStyle}>
      <canvas ref={canvasEl} style={canvasStyle}></canvas>
    </div>
  );
}
