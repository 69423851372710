import {
  FirestoreDataConverter,
  QueryDocumentSnapshot,
  SnapshotOptions,
  Timestamp,
} from "firebase/firestore";
import { ShotType, StockShotInfo, ThrowStyle } from "../../model/stockShot";
import { ClientCoreMetrics, CoreMetrics } from "./analysisSet";
import coreMetricsConverter, { convertStatsToMetrics, removeCoreMetrics } from "./coreMetrics";
import { CoreStats } from "../../model/CoreStats";
import { Handedness } from "../../model/UserSettings";

export type ThrowMetrics = Pick<
  CoreStats,
  | "speedMph"
  | "rotPerSec"
  | "offAxisDegrees"
  | "hyzerAngle"
  | "noseAngle"
  | "uphillAngle"
  | "correctedHyzerAngle"
  | "correctedNoseAngle"
  | "upsideDown"
>;
export type StockShotDocumentData = StockShotInfo & { avg: CoreStats };

export type StockShot = StockShotInfo & ClientCoreMetrics & { avg: ThrowMetrics };

export interface StockShotInfo {
  throwIds: [];
  createTime: Timestamp;
  handedness: Handedness;
  throwStyle: ThrowStyle;
  shotType: ShotType;
  avg: ThrowMetrics;
}

export const stockShotConverter: FirestoreDataConverter<StockShot> = {
  fromFirestore: (snapshot: QueryDocumentSnapshot, options: SnapshotOptions) => {
    const data = snapshot.data(options);

    const clientMetrics = convertStatsToMetrics(data.avg as CoreStats);
    return {
      id: snapshot.id,
      ...data,
      ...clientMetrics,
    }; // Adjust this mapping as necessary
  },
  toFirestore: (modelObject: StockShot): StockShotDocumentData => {
    const { id: _id, ...stockShot } = modelObject;
    console.log(stockShot);
    const stockShotDocument = {
      createTime: Timestamp.now(), // If already created, the spread below should overwrite this
      avg: removeCoreMetrics(stockShot.avg),
      ...removeCoreMetrics(stockShot),
    };

    return stockShotDocument;
  },
};
