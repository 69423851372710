import { fromFirestore, toFirestore } from "@/firebase/converters/converter";
import type { SimulatorPermission, SimulatorPermissionDocument } from "@/types/simulator";
import type { FirestoreDataConverter } from "firebase/firestore";

export const simulatorPermissionsConverter: FirestoreDataConverter<
  SimulatorPermission,
  SimulatorPermissionDocument
> = {
  fromFirestore,
  toFirestore,
};
