import React from "react";

import { useSplitTreatments } from "@splitsoftware/splitio-react";
import FEATURE_FLAGS, { FeatureFlag } from "../featureFlags";
import { User } from "firebase/auth";
import useRoleCheck from "./useRoleCheck";

export default function useFeatureFlags(user: User | null | undefined) {
  const { isBetaUser, isAdmin, isStore, isPremiumStore, isEarlyAccessUser } = useRoleCheck(user);
  const { treatments } = useSplitTreatments({ names: Object.keys(FEATURE_FLAGS) });

  return Object.entries(treatments).reduce(
    (acc, [flag, treatment]) => {
      acc[flag as FeatureFlag] = [
        "on",
        isBetaUser && "beta",
        isAdmin && "admin",
        isStore && "store",
        isEarlyAccessUser && "early-access",
        isPremiumStore && "store+",
      ].includes(treatment.treatment);
      return acc;
    },
    {} as Record<FeatureFlag, boolean>,
  );
}
