import Title from "./Title";
import { useEffect, useState } from "react";
import { Typography, Container, Grid, Paper, Switch, FormControlLabel } from "@mui/material";
import Simulate3D, { FlightPathParams } from "./Simulate3D";
import SimSlider, {
  getHyzerProps,
  getMphProps,
  getNoseUpProps,
  getRunupProps,
  getSpinProps,
  getUphillProps,
  getWindSpeedProps,
} from "./SimSlider";
import { FlightNumberBoxes, FlightNumberSliders } from "./FlightNumberChooser";
import { getUserId, loadSummary, mphToMps, radPerSecToRpm } from "../summaryUtils";
import { getQueryMap, getThrowIdQueryParam, setQueryString } from "../queryUtils";
import { downloadTrack, TrackPoint } from "../trackUtils";
import { FlightNumbers, ThrowSummary } from "../model/throwSummary";
import { CoreStats } from "../model/CoreStats";
import { useAuthState } from "react-firebase-hooks/auth";
import { getAuth } from "firebase/auth";
import { firebaseApp } from "../firebaseConfig";
import { DiscStats } from "../discStats";
import CircularSlider from "@fseehawer/react-circular-slider";
import { ThrowSummaryAndId } from "../latestDashboard";

const queryMap = getQueryMap();
const throwId: string | undefined = getThrowIdQueryParam();

function getOrDefault(num: undefined | string, defaultVal: number) {
  if (num == undefined) {
    return defaultVal;
  }
  return Number(num);
}

export const DEFAULT_DISC_STATE: FlightNumbers = {
  speed: getOrDefault(queryMap.get("speed"), 9),
  glide: getOrDefault(queryMap.get("glide"), 5),
  turn: getOrDefault(queryMap.get("turn"), -1),
  weight: getOrDefault(queryMap.get("weight"), 0.175),
};

export const DEFAULT_PRO_DISC_STATE: FlightNumbers = {
  speed: getOrDefault(queryMap.get("speed"), 12),
  glide: getOrDefault(queryMap.get("glide"), 5),
  turn: getOrDefault(queryMap.get("turn"), 0.5),
  weight: getOrDefault(queryMap.get("weight"), 0.175),
};

export const DEFAULT_THROW_STATE: FlightPathParams = {
  v: getOrDefault(queryMap.get("v"), 55 * mphToMps),
  runup: getOrDefault(queryMap.get("runup"), 0),
  spin: getOrDefault(queryMap.get("spin"), -1000 / radPerSecToRpm),
  uphill_degrees: getOrDefault(queryMap.get("uphill_degrees"), 5),
  hyzer_degrees: getOrDefault(queryMap.get("hyzer_degrees"), 10),
  nose_up_degrees: getOrDefault(queryMap.get("nose_up_degrees"), -2.5),
  flight_numbers: DEFAULT_DISC_STATE,
  wind_angle: getOrDefault(queryMap.get("wind_angle"), 0),
  wind_speed: getOrDefault(queryMap.get("wind_speed"), 0),
};

function putParamsInQueryString(params: FlightPathParams) {
  const obj = { ...params, ...params.flight_numbers };
  // @ts-ignore
  delete obj.flight_numbers;
  setQueryString(obj);
}

function getFlightNums(summary: ThrowSummary, old: FlightNumbers): FlightNumbers {
  if (summary.estimatedFlightNumbers) {
    return summary.estimatedFlightNumbers;
  }
  const common = {
    speed: 12,
    glide: 5,
    weight: 0.175,
  };
  switch (summary.estimatedDisc) {
    case "flippy_destroyer":
      return { ...common, turn: -3 };
    case "destroyer":
      return { ...common, turn: -2 };
    case "stable_destroyer":
      return { ...common, turn: -0.68 };
    case "beefy_destroyer":
      return { ...common, turn: 0 };
    default:
      return old;
  }
}

function getHyzer(summary): number {
  if (summary.correctedHyzerAngle) {
    if (summary.upsideDown) {
      return -1.0 * DiscStats.flipHyzer(summary.correctedHyzerAngle);
    }
    return summary.correctedHyzerAngle;
  }
  return summary.hyzerAngle;
}

function getCircularSlider(
  flightState: FlightPathParams,
  setFlightState: (
    value: ((prevState: FlightPathParams) => FlightPathParams) | FlightPathParams,
  ) => void,
) {
  return (
    <CircularSlider
      label={"Wind Direction"}
      min={0}
      max={360}
      dataIndex={flightState.wind_angle ? (flightState.wind_angle * 2) / Math.PI : 4}
      data={["Tail", "R to L", "Head", "L to R", "Tail"]}
      direction={-1}
      knobPosition={"top"}
      // appendToValue="°"
      valueFontSize="3rem"
      onChange={(value) => {
        let num = 0;
        if (value === "Tail") {
          num = 0;
        } else if (value === "R to L") {
          num = 90;
        } else if (value === "Head") {
          num = 180;
        } else if (value === "L to R") {
          num = 270;
        }

        setFlightState((prevState) => {
          return { ...prevState, wind_angle: (num * Math.PI) / 180 };
        });
      }}
      trackColor="#eeeeee"
      // progressColorFrom={isDragging ? "#F0A367" : "#00bfbd"}
      // progressColorTo={isDragging ? "#F65749" : "#009c9a"}
      // labelColor={TECHDISC_BLUE}
      // knobColor={TECHDISC_BLUE}
      trackSize={Math.max(10, flightState.wind_speed ?? 0)}
      progressSize={Math.max(10, flightState.wind_speed ?? 0) - 5}
    ></CircularSlider>
  );
}

export function flightPathFromSummary(summary: CoreStats): FlightPathParams {
  return {
    v: summary.speedMph * mphToMps,
    // the simulator uses Z as up so we reverse it
    spin: -summary.rotPerSec * 2 * Math.PI,
    uphill_degrees: summary.uphillAngle,
    hyzer_degrees: getHyzer(summary),
    nose_up_degrees: summary.correctedNoseAngle || summary.noseAngle,
  };
}

function DashboardContent() {
  const [user, userLoading, userError] = useAuthState(getAuth(firebaseApp));
  const [track, setTrack] = useState<TrackPoint[]>();
  const [summary, setSummary] = useState<ThrowSummaryAndId>();
  const [flightState, setFlightState] = useState(DEFAULT_THROW_STATE);
  const [distance, setDistance] = useState<number | undefined>(undefined);
  const [unlock, setUnlock] = useState(false);

  useEffect(() => {
    if (!throwId || userLoading) {
      return;
    }
    let isCancelled = false;
    (async () => {
      const summaryP = loadSummary(getUserId(), throwId);
      const track = await downloadTrack(throwId);
      const summary = await summaryP;
      if (!isCancelled && track && summary) {
        setDistance(summary.estimatedFeet);
        setTrack(track);
        setSummary({ ...summary, id: throwId });
        setFlightState((old) => {
          const flightPath = flightPathFromSummary(summary);
          return { ...flightPath, flight_numbers: getFlightNums(summary, old.flight_numbers) };
        });
      }
    })();
    return () => {
      isCancelled = true;
    };
  }, [user, userLoading]);

  if (!throwId && !userLoading) {
    putParamsInQueryString(flightState);
  }
  const frozen = !unlock && !!throwId;
  const setFlightNumbers: (
    value: ((prevState: FlightNumbers) => FlightNumbers) | FlightNumbers,
  ) => void = (value) => {
    setFlightState((prevState) => {
      const newNumbers = typeof value === "function" ? value(prevState.flight_numbers) : value;
      return {
        ...prevState,
        flight_numbers: newNumbers,
      };
    });
  };

  const mphProps = getMphProps(flightState, setFlightState);
  const noseProps = getNoseUpProps(flightState, setFlightState);
  const hyzerProps = getHyzerProps(flightState, setFlightState);
  const uphillProps = getUphillProps(flightState, setFlightState);
  const spinProps = getSpinProps(flightState, setFlightState);
  const runupProps = getRunupProps(flightState, setFlightState);
  const windSpeedProps = getWindSpeedProps(flightState, setFlightState);
  return (
    <Container sx={{ mb: 4, flexGrow: 1, flexBasis: "100%", flexShrink: 0 }}>
      <Grid item sx={{ mt: 4, mb: 4 }} xs={12}>
        <Paper
          sx={{
            p: 2,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Simulate3D
            flightParams={[flightState]}
            setDistance={setDistance}
            previousTrack={track}
          />
        </Paper>
      </Grid>
      <Grid container spacing={3}>
        <Grid container direction="column" item xs={4}>
          <FlightNumberSliders
            flight_numbers={flightState.flight_numbers}
            setFlightNumbers={setFlightNumbers}
          />
          <FlightNumberBoxes flight_numbers={flightState.flight_numbers} />
        </Grid>
        <Grid container direction="column" item xs={4}>
          <Paper
            sx={{
              p: 2,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography component="span" display="inline" variant="h5">
              {"Throw Details"}
            </Typography>
            {/*{throwId && <SimSlider {...runupProps} />}*/}
            {throwId && (
              <FormControlLabel
                control={
                  <Switch
                    checked={unlock}
                    onChange={(event) => event.target.checked && setUnlock(event.target.checked)}
                    disabled={unlock}
                    name="unlockToggle"
                    color="primary"
                  />
                }
                label="Unlock"
              />
            )}
            <SimSlider {...mphProps} isFrozen={frozen} />
            <SimSlider {...noseProps} isFrozen={frozen} />
            <SimSlider {...hyzerProps} isFrozen={frozen} />
            <SimSlider {...uphillProps} isFrozen={frozen} />
            <SimSlider {...spinProps} isFrozen={frozen} />
            {!throwId && (
              <FormControlLabel
                control={
                  <Switch
                    checked={flightState.spin > 0}
                    onChange={(event) => {
                      setFlightState((prevState) => {
                        const newSpin = Math.abs(prevState.spin) * (event.target.checked ? 1 : -1);
                        return { ...prevState, spin: newSpin };
                      });
                    }}
                    name="reverseSpinToggle"
                    color="primary"
                  />
                }
                label="Reverse Spin"
              />
            )}
            {!throwId && (
              <FormControlLabel
                control={
                  <Switch
                    checked={DiscStats.isUpsideDown(flightState.hyzer_degrees)}
                    onChange={(event) => {
                      setFlightState((prevState) => {
                        let newHyzer = prevState.hyzer_degrees;
                        let newSpin = prevState.spin;
                        if (DiscStats.isUpsideDown(newHyzer) !== event.target.checked) {
                          newHyzer = DiscStats.flipUpsideDown(newHyzer);
                          newSpin = -newSpin;
                        }
                        return { ...prevState, hyzer_degrees: newHyzer, spin: newSpin };
                      });
                    }}
                    name="upsideDownToggle"
                    color="primary"
                  />
                }
                label="Upside Down"
              />
            )}
          </Paper>
        </Grid>
        <Grid container direction="column" item xs={4}>
          <Paper
            sx={{
              m: 2,
              p: 2,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Title>{"Distance"}</Title>
            <Typography component="span" display="inline" variant="h4">
              {distance?.toFixed(2)}
            </Typography>
          </Paper>
          {/*{!throwId && (*/}
          {/*  <Paper sx={{ m: 2, p: 2, display: "flex", flexDirection: "column" }}>*/}
          {/*    <SimCopyLinkButton setUrl={() => putParamsInQueryString(flightState)} />*/}
          {/*  </Paper>*/}
          {/*)}*/}
          {/*<Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>*/}
          {/*  {getCircularSlider(flightState, setFlightState)}*/}
          {/*  <SimSlider {...windSpeedProps} isFrozen={frozen} />*/}
          {/*  /!*<Typography component="span" display="inline" variant="h6">*!/*/}
          {/*  /!*    {"Ground friction"}*!/*/}
          {/*  /!*</Typography>*!/*/}
          {/*</Paper>*/}
        </Grid>
      </Grid>
    </Container>
  );
}

export default function SimDashboard() {
  return <DashboardContent />;
}
