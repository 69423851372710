import {
  QueryDocumentSnapshot,
  serverTimestamp,
  type DocumentData,
  type SnapshotOptions,
  type Timestamp,
} from "firebase/firestore";

export interface Document extends DocumentData {
  id: string;
}

export interface MutableDocumentData extends DocumentData {
  createdAt: Timestamp;
  updatedAt: Timestamp;
}

export function toFirestore<AppModelType extends Document, DbModelType extends MutableDocumentData>(
  data: AppModelType,
): DbModelType {
  const { id, ...rest } = data;
  const now = serverTimestamp();
  return {
    ...rest,
    updatedAt: now,
  } as unknown as DbModelType;
}

export function fromFirestore<AppModelType extends Document>(
  snapshot: QueryDocumentSnapshot,
  options?: SnapshotOptions,
): AppModelType {
  const data = snapshot.data(options);

  return { id: snapshot.id, ...data } as AppModelType;
}
