import { getStorage, ref, StorageReference } from "firebase/storage";
import { firebaseApp } from "../firebaseConfig";
import { Device } from "../model/device";

export function getDeviceColor(devices: Map<string, Device>, deviceId: string): string | undefined {
  return devices ? devices?.get(deviceId)?.color : "#FFF";
}

export function getStorageRef(path: string): StorageReference {
  const storage = getStorage(firebaseApp);
  return ref(storage, path);
}
